import { Component } from '@angular/core';

@Component({
  selector: 'app-csr',
  templateUrl: './csr.component.html'
})

export class CsrComponent {
  constructor() { }
}
