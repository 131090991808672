import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

//Service
import { UserLoggedInService } from './user-logged-in.service';

//documentacion de interceptor.
// https://medium.com/@insomniocode/angular-autenticaci%C3%B3n-usando-interceptors-a26c167270f4

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private readonly router: Router,
    private readonly userLoggedInService: UserLoggedInService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });

    return next.handle(req).pipe(
      catchError((err: String) => {
        console.log(err);
        if (err === 'Unauthorized') {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          this.userLoggedInService.setUserLoggedIn(false);
          this.router.navigateByUrl('/account/login');
        }

        return throwError(err);

      })
    );
  }
}
