import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { MenuItem } from 'primeng/api';

@Injectable()
export class AppBreadcrumbService {

  private itemsSource = new Subject<MenuItem[]>();
  private arrayMenuItems = new BehaviorSubject<MenuItem[]>([]);

  itemsHandler = this.itemsSource.asObservable();

  getMenuItems(): Observable<MenuItem[]> {
    return this.arrayMenuItems.asObservable();
  }

  setItems(items: MenuItem[]) {
    this.itemsSource.next(items);
    this.arrayMenuItems.next(items);
  }
}
