//Angular
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

//Services
import { DepartmentService } from '../../csr/department/department.service';
import { ProjectService } from '../project.service';
import { AppBreadcrumbService } from '../../menu/app.breadcrumb.service';

//Core
import { Department } from '../../csr/department/department';
import { Project } from '../project';

//Primeng
import { MessageService, MenuItem } from 'primeng/api';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html'
})
export class ProjectsComponent implements OnInit {

  public title = 'Projects';
  public projects: Project[] = [];
  public projectId: string;
  public cols: any[];
  public loading: boolean;
  public deleteModal = false;
  public departmentId = 0;
  public department: Department;
  public items: MenuItem[];

  constructor(private readonly projectService: ProjectService,
    private readonly departmentService: DepartmentService,
    private readonly messageService: MessageService,
    private readonly router: Router,
    private readonly breadcrumbService: AppBreadcrumbService) {

    this.breadcrumbService.getMenuItems().subscribe(response => {

      this.items = response;
    });
  }

  public ngOnInit(): void {

    this.departmentId = this.departmentService.getDepartmentID();

    this.getProjects();

    const filterMenu = this.items.filter(a => a.label === 'projects');

    if (filterMenu.length === 0) {

      const newItem = { label: 'projects', routerLink: '/project/projects/0' } as MenuItem;

      this.items.push(newItem);

      this.breadcrumbService.setItems(this.items);
    }
  }

  public getProjects(): void {

    if (this.departmentId === 0) {
      this.router.navigate(['/cmr/department', 1]);
    }

    this.projectService.getProjectsPerDepartment(this.departmentId)
      .subscribe(res => {
        this.projects = res as Project[];

        this.getDepartment();

        this.loadColumns();

        this.loading = false;
      },
        (error: any) => {

          this.messageService.add({ key: 'projectskey', severity: 'error', summary: 'Error', detail: error });         

        });
  }

  public getDepartment(): void {

    this.departmentService.getDepartment(this.departmentId)
      .subscribe((res: any) => {
        this.department = res as Department;
        this.title = `Projects [${this.department.name}]`;
      },
        (error: any) => {

          this.messageService.add({ key: 'projectskey', severity: 'error', summary: 'Error', detail: error });

        });
  }

  public selected(id: number, strValue: string): void {

    if (strValue === 'Edit') {
      this.router.navigate([`/project/project-details/${id}`]);
    }
    else {
      this.router.navigate(['/project/project-details/0']);
    }
  }

  public loadColumns(): void {

    this.cols = [
      { field: 'id', header: 'Id' },
      { field: 'projectname', header: 'Project Name' },
      { field: 'currentversion', header: 'Current Version' },
      { field: 'inactive', header: 'Inactive' },
      { field: 'orderby', header: 'Order By' }
    ];

    this.loading = true;
  }

  public showDeleteConf(id: string): void {

    this.projectId = id;
    this.deleteModal = true;
  }

  public onDelete(): void {

    this.deleteModal = false;

    this.projectService.deleteProject(this.projectId)
      .subscribe((res: any) => {

        this.messageService.add({ key: 'clientskey', severity: 'success', summary: 'Delete', detail: 'Record deleted successfully' });

        this.getProjects();
      },
        (error: any) => {

          this.messageService.add({ key: 'projectskey', severity: 'error', summary: 'Error', detail: error });

        });
  }

}
