//Angular
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

//Services
import { ProjectService } from '../project.service';
import { DepartmentService } from '../../csr/department/department.service';
import { AppBreadcrumbService } from '../../menu/app.breadcrumb.service';

//Core
import { Project } from '../project';
import { Department } from '../../csr/department/department';

//Primeng
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html'
})
export class ProjectDetailsComponent implements OnInit {

  public title = 'Project-Details';
  public project: Project;
  public departments: Department[] = [];
  public department: Department;
  public IsAddNew: boolean;
  public loading = false;

  constructor(private readonly projectService: ProjectService,
    private readonly departmentService: DepartmentService,
    private readonly messageService: MessageService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly _location: Location,
    private readonly breadcrumbService: AppBreadcrumbService  ) {
  }

  public ngOnInit(): void {

    const id = this.route.snapshot.params['id'];

    this.project = { id } as Project;

    if (id === '0') {
      this.IsAddNew = true;
      this.title = 'Add New';

      this.breadcrumbService.setItems([
        { label: 'projects', routerLink: '/project/projects/0' },
        { label: 'project-details', routerLink: ['/project/project-details/0'] }
      ]);
    }
    else {
      this.IsAddNew = false;
      this.title = `Edit project: ${id}`;
      this.getProject(id);

      this.breadcrumbService.setItems([
        { label: 'projects', routerLink: '/project/projects/0' },
        { label: 'project-details', routerLink: [`/project/project-details/${id}`] }
      ]);
    }

    this.getDepartments();    
  }

  public getProject(id: any): void {

    this.projectService.getProject(id)
      .subscribe(res => {
        this.project = res as Project;
        this.getDepartment(this.project.departmentId);
      },
        (error: any) => {

          this.messageService.add({ key: 'projectdetailskey', severity: 'error', summary: 'Error', detail: error });

        });
  }

  public onSubmit(): void {

    this.loadingSubmit(true);
    this.project.departmentId = this.department.id;

    if (this.IsAddNew === true) {

      this.projectService.addProject(this.project)
        .subscribe(res => {

          this.loadingSubmit(false);
          this.messageService.add({ key: 'clientdetailskey', severity: 'success', summary: 'Add', detail: 'The record added successfully.' });
          this.router.navigate(['/project/projects/0']);
        },
          (error: any) => {

            this.loadingSubmit(false);
            this.messageService.add({ key: 'projectdetailskey', severity: 'error', summary: 'Error', detail: error });
          });
    }
    else {

      this.projectService.editProject(this.project)
        .subscribe(res => {

          this.loadingSubmit(false);
          this.messageService.add({ key: 'clientdetailskey', severity: 'success', summary: 'Edit', detail: 'The record updated successfully.' });
          this.router.navigate(['/project/projects/0']);
        },
          (error: any) => {

            this.loadingSubmit(false);
            this.messageService.add({ key: 'projectdetailskey', severity: 'error', summary: 'Error', detail: error });
          });
    }
  }

  public getDepartment(id: number): void {

    this.departmentService.getDepartment(id)
      .subscribe(res => this.department = res as Department,
        (error: any) => {

          this.messageService.add({ key: 'projectdetailskey', severity: 'error', summary: 'Error', detail: error });

        }
      );
  }

  public getDepartments(): void {

    this.departmentService.getDepartments()
      .subscribe((res: any) => this.departments = res as Department[],
        (error: any) => {

          this.messageService.add({ key: 'projectdetailskey', severity: 'error', summary: 'Error', detail: error });

        }
      );
  }

  public backClicked(): void {

    this._location.back();
  }

  public loadingSubmit(value: boolean): void {

    if (value === true) {
      this.loading = true;
    }
    else {
      this.loading = false;
    }
  }

}
