//Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from '../core/base.service';

//Core
import { Project } from './project';

@Injectable()
export class ProjectService extends BaseService {

  public _baseUrlToAPI = 'api/project';

  constructor(private http: HttpClient) {
    super();
  }

  public getProject(id: number): Observable<Project> {

    return this.http.get<Project>(`${this._baseUrlToAPI}/${id}`);
  }

  public getProjects(): Observable<Project[]> {

    return this.http.get<Project[]>(this._baseUrlToAPI);
  }

  public getProjectsPerDepartment(departmentid: number): Observable<Project[]> {

    return this.http.get<Project[]>(`${this._baseUrlToAPI}/perdepartment/${departmentid}`);
  }

  public addProject(project: Project): Observable<any> {
    
    return this.http.post<any>(this._baseUrlToAPI, project, { headers: this.PutPostHeaders });
  }

  public editProject(project: Project): Observable<any> {
    
    return this.http.put<any>(this._baseUrlToAPI, project, { headers: this.PutPostHeaders });
  }

  public deleteProject(id: string): Observable<any> {

    return this.http.delete<any>(`${this._baseUrlToAPI}/${id}`);
  }

}
