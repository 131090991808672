//Angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

//Shared
import { SharedModule } from '../shared/shared.module';

//Services
import { ClientsService } from './client.service';

//Component
import { ClientDetailsComponent } from './client-details/client-details.component';
import { ClientComponent } from './client.component';
import { ClientsComponent } from './clients/clients.component';

@NgModule({
  imports: [

    CommonModule,
    SharedModule
  ],
  exports: [
  ],
  declarations: [
    ClientComponent,
    ClientsComponent,
    ClientDetailsComponent
  ],

  providers: [

    ClientsService
  ]
})
export class ClientModule { }
