import { Component } from '@angular/core';

//Service
import { FileService } from '../shared/service/file.service';
import { AppBreadcrumbService } from '../menu/app.breadcrumb.service';

import { saveAs } from 'file-saver';

@Component({
  selector: 'app-home-component',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  styles: []
})

export class HomeComponent {

  public message: string;

  constructor(private fileService: FileService,
    private readonly breadcrumbService: AppBreadcrumbService) {
    this.message = 'Customer Modification Request';

    this.breadcrumbService.setItems([
      { label: '', routerLink: ['/'] }
    ]);

  }

  public downloadTeanviewerLWH_App(): void {
    this.fileService.downloadTeanviewerLWH_App().subscribe((blob: any) => {
      const newBlob = new Blob([blob], { type: 'application/octet-stream' });
      saveAs(newBlob, 'downloadTeanviewerLWH_App.zip');
    });
  }

}
