//Angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

//Shared
import { SharedModule } from '../shared/shared.module';

//Services
import { CompensatoryTimeService } from './compensatory-time.service';

//Component
import { CompensatoryTimeComponent } from './compensatory-time.component';
import { UserCompensatoryTimeComponent } from './user-compensatory-time/user-compensatory-time.component';

@NgModule({
  imports: [

    CommonModule,
    SharedModule
  ],
  exports: [
  ],
  declarations: [
    CompensatoryTimeComponent,
    UserCompensatoryTimeComponent
  ],

  providers: [

    CompensatoryTimeService
  ]
})
export class CompensatoryTimeModule { }
