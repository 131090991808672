import {Component} from '@angular/core';

@Component({
    selector: 'app-footer',
    template: `
        <div class="footer">
            <div class="card clearfix">
                <span class="footer-text-left">CSR - Version: {{AppVersion}}</span>
                <span class="footer-text-right">
                    &copy; {{currentYear}} Labwarehouse Inc., All Rights Reserved
                </span>
            </div>
        </div>
    `
})
export class AppFooterComponent {

  public currentYear: number = new Date().getFullYear();
  public AppVersion = '3.1.38';

}
