import { Component } from '@angular/core';

@Component({
  selector: 'app-guide',
  templateUrl: './guide.component.html'
})

export class GuideComponent {
  constructor() { }
}
