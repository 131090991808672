//Angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

//Shared
import { SharedModule } from '../shared/shared.module';

//Services
import { GuideService } from './guide.service';

//Component
import { GuideAddComponent } from './guide-add/guide-add.component';
import { GuidesPerProjectComponent } from './guides-per-project/guides-per-project.component';
import { GuideGroupByProjectsComponent } from './guide-group-by-projects/guide-group-by-projects.component';
import { GuideComponent } from './guide.component';

@NgModule({
  imports: [

    CommonModule,
    SharedModule
  ],
  exports: [
  ],
  declarations: [
    GuideComponent,
    GuideAddComponent,
    GuideGroupByProjectsComponent,
    GuidesPerProjectComponent
  ],

  providers: [

    GuideService
  ]
})
export class GuideModule { }
