//Angular
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

//Services
import { AccountService } from '../account.service';
import { DepartmentService } from '../../csr/department/department.service';
import { AppBreadcrumbService } from '../../menu/app.breadcrumb.service';

//Core
import { Client } from '../../client/client';
import { Department } from '../../csr/department/department';
import { Role } from '../../account/model/role';
import { User } from '../../account/model/user';
import {PermissionsReturnValue } from '../../csr/department/permissions-return-value';
import { UserDepartmetsPermission } from '../../csr/department/user-departments-permission';
import { ChangePassword } from '../../account/model/change-password';

//Primeng
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html'
})
export class UserComponent implements OnInit {

  public title = 'User';
  public _user: User;
  public _client: Client;
  public loading: boolean;
  public colsPermissions: any[];
  public cols: any[];
  public permissionsReturnValue: PermissionsReturnValue[] = [];
  public _roles: Role[];
  public _userRoles: Role[];
  public selectedRole: Role;
  public departments: Department[] = [];
  public selectedDepartment: Department;
  public showDialogDeleteRole = false;
  public deleteModal = false;
  public departmentId = 0;
  public resetModal = false;
  public _userResetPassword: ChangePassword;
  public ResetPassword: string;

  constructor(private readonly accountService: AccountService,
    private readonly departmentService: DepartmentService,
    private readonly messageService: MessageService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly breadcrumbService: AppBreadcrumbService  ) { }

  public ngOnInit(): void {
    const id = this.route.snapshot.params['id'];
    this._user = {} as User;
    this._client = {} as Client;
    this.selectedDepartment = { id: 0, name: '', description: '', inactive: false } as Department;

    this.getUser(id);
    this.getRoles();
    this.getUserRoles();
    this.getDepartments();

    this.breadcrumbService.setItems([
      { label: 'account' },
      { label: 'users', routerLink: ['/account/users/0'] },
      { label: 'user-details', routerLink: [`/account/user/${id}`] }
    ]);
  }

  public getUser(id: string): void {    

    this.accountService.getUser(id)
      .subscribe(res => this._user = res as User,
        (error: any) => {

          this.messageService.add({ key: 'userkey', severity: 'error', summary: 'Error', detail: error });

        },
        () => {
          this._client = this._user.client;
          this.getAllUserPermission(this._user.id);
        }
      );
  }

  public getAllUserPermission(userid: string): void {

    this.departmentService.getAllUserPermission(userid)
      .subscribe(res => {
        this.permissionsReturnValue = res as PermissionsReturnValue[];

        this.loadColumnsPermissions();

        this.loading = false;
      },
        (error: any) => {

          this.messageService.add({ key: 'userkey', severity: 'error', summary: 'Error', detail: error });

        });
  }

  public loadColumnsPermissions(): void {

    this.colsPermissions = [
      { field: 'departmentName', header: 'Permission' }
    ];

    this.loading = true;
  }

  public getRoles(): void {

    this.accountService.getRoles()
      .subscribe(res => this._roles = res as Role[],
        (error: any) => {

          this.messageService.add({ key: 'userkey', severity: 'error', summary: 'Error', detail: error });

        });

  }

  // from database
  public getUserRoles(): void {
    const id = this.route.snapshot.params['id'];

    this.accountService.getUserRoles(id)
      .subscribe(res => {
        this._userRoles = res as Role[];

        this.loadColumns();

        this.loading = false;
      },
        (error: any) => {

          this.messageService.add({ key: 'userkey', severity: 'error', summary: 'Error', detail: error });

        });
  }

  public loadColumns(): void {

    this.cols = [
      { field: 'name', header: 'Role' }
    ];

    this.loading = true;
  }

  public getDepartments(): void {

    this.departmentService.getDepartments()
      .subscribe((res: any) => {
        this.departments = res as Department[];
        this.loadColumnsPermissions();
      },
        (error: any) => {

          this.messageService.add({ key: 'userkey', severity: 'error', summary: 'Error', detail: error });

        });
  }

  public addNewRole(): void {

    if (this.selectedRole === undefined) {
      
      this.messageService.add({ key: 'userkey', severity: 'warn', summary: 'Warn', detail: 'Please select role first and try again.' });

      return;
    }

    const roleDuplicate = this._userRoles.find(x => x.id === this.selectedRole.id) as Role;

    if (roleDuplicate !== undefined) {
      
      this.messageService.add({ key: 'userkey', severity: 'warn', summary: 'Warn', detail: 'This user role exist.' });

      return;
    }

    this.accountService.addNewRole(this._user.id, this.selectedRole.id.toString())
      .subscribe(res => this._userRoles = res as Role[],
        (error: any) => {

          this.messageService.add({ key: 'userkey', severity: 'error', summary: 'Error', detail: error });

        },
        () => {
          
          this.messageService.add({ key: 'userkey', severity: 'success', summary: 'New Role', detail: 'New role was added successfully.' });

        }
      );
  }

  public addNewPermission(): void {

    if (this.selectedDepartment === undefined) {
      
      this.messageService.add({ key: 'userkey', severity: 'warn', summary: 'Warn', detail: 'Please select permission first and try again.' });

      return;
    }

    const permissionDuplicate = this.permissionsReturnValue.find(x => x.departmentId === this.selectedDepartment.id) as PermissionsReturnValue;

    if (permissionDuplicate !== undefined) {
      
      this.messageService.add({ key: 'userkey', severity: 'warn', summary: 'Warn', detail: 'This user permission exists' });

      return;
    }

    const userDepartmetsPermission = { id: 0, userId: this._user.id, departmentId: this.selectedDepartment.id } as UserDepartmetsPermission;

    this.departmentService.addNewPermission(userDepartmetsPermission)
      .subscribe(res => this.permissionsReturnValue = res as PermissionsReturnValue[],
        (error: any) => {

          this.messageService.add({ key: 'userkey', severity: 'error', summary: 'Error', detail: error });

        },
        () => {
          
          this.messageService.add({ key: 'userkey', severity: 'success', summary: 'New Permission', detail: 'The permission has been saved successfully.' });

        }
      );
  }

  public selectRoleToDelete(role: Role): void {

    this.selectedRole = role;
    this.showDialogDeleteRole = true;
  }

  public onDelete(): void {

    this.accountService.deleteUserRole(this._user.id, this.selectedRole.id.toString())
      .subscribe((res: any) => {

        this.showDialogDeleteRole = false;

        this.messageService.add({ key: 'userkey', severity: 'success', summary: 'Delete', detail: 'Record deleted successfully' });

        this.getUserRoles();
      },
        (error: any) => {

          this.showDialogDeleteRole = false;

          this.messageService.add({ key: 'userkey', severity: 'error', summary: 'Error', detail: error });

        });
  }

  public showDeleteConf(permission: UserDepartmetsPermission): void {

    this.departmentId = permission.departmentId;
    this.deleteModal = true;
  }

  public deleteUserPermission(): void {

    this.departmentService.deleteUserPermission(this._user.id, this.departmentId)
      .subscribe((res: any) => {

        this.deleteModal = false;

        this.messageService.add({ key: 'userkey', severity: 'success', summary: 'Delete', detail: 'Record deleted successfully' });

        this.permissionsReturnValue = [];
        this.getAllUserPermission(this._user.id);
      },
        (error: any) => {

          this.deleteModal = false;

          this.messageService.add({ key: 'userkey', severity: 'error', summary: 'Error', detail: error });

        });
  }

  public resetPassword(): void {

    this.resetModal = false;

    this._userResetPassword = { userName: this._user.userName, email: this._user.email, oldpassword: 'oldpassword', newpassword: 'password', confirmpassword: 'Pass@123'} as ChangePassword;

    this.accountService.resetPassword(this._userResetPassword)
      .subscribe((res: any) => {
        
        this.messageService.add({ key: 'userkey', severity: 'success', summary: 'Reset Password', detail: `User: ${this._user.name} , the password reset successfully.` });

        this.ResetPassword = 'password';

      },
        (error: any) => {

          this.messageService.add({ key: 'userkey', severity: 'error', summary: 'Error', detail: error });

        });
  }

  public fileChangeEvent(fileInput: any): void {

    this.onUpload(fileInput.target.files[0]);
  }

  public onUpload(file: any): void {

    if (this.isUserLoggedIn()) {

      let userLogged = this.getlocalUser();

      const formData = new FormData();

      formData.append(file.name, file);
      formData.append('userid', userLogged.id);

      this.accountService.uploadAvatar(formData)
        .subscribe((res: any) => {

          let resultUser = res as User;

          this.getUser(resultUser.id);

          this.messageService.add({ key: 'dashboardkey', severity: 'success', summary: 'Avatar', detail: 'File upload successful' });

        },
          (error: any) => {

            this.messageService.add({ key: 'dashboardkey', severity: 'error', summary: 'Error', detail: error });

          });
    }
  }

  public isMobile() {
    return window.innerWidth <= 640;
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

  public getlocalUser(): User {

    let userLogged = this.accountService.getLoggedInUser();

    if (userLogged == undefined) {
      this.router.navigate(['/account/login'])
    }

    return userLogged;
  }

}
