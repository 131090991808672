import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-component',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {

  constructor(public primengConfig: PrimeNGConfig) {
  }
  ngOnInit() {
    this.primengConfig.ripple = true;
  }
}
