//Angular
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

//Services
import { IssueTypeService } from './issue-type.service';
import { DepartmentService } from '../../csr/department/department.service';

//Core
import { Department } from '../../csr/department/department';
import { IssueType } from './issue-type';

//Primeng
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-issue-type',
  templateUrl: './issue-type.component.html'
})
export class IssueTypeComponent implements OnInit {

  public title = 'Issue Type';
  public issueTypes: IssueType[] = [];
  public issueType: IssueType;
  public loading = false;
  public cols: any[];
  public departments: Department[] = [];
  public department: Department;
  public deleteModal = false;
  public issueTypeId: number;
  public displayDialog = false;
  public dialogHeaderText: string;
  public IsAddNew: boolean;

  constructor(private readonly issueTypeService: IssueTypeService,
    private readonly departmentService: DepartmentService,
    private readonly messageService: MessageService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly _location: Location) {
  }  

  public ngOnInit(): void {

    this.issueType = { id: 0 } as IssueType;
    this.getIssueTypes();
  }

  public getIssueTypes(): void {

    this.loading = true;

    this.issueTypeService.getIssueTypes()
      .subscribe((res: any) => {

        this.issueTypes = res as IssueType[];
        
        this.loading = false;
        this.loadColumns();
        this.getDepartments();
      },
        (error: any) => {

          this.messageService.add({ key: 'issuetypekey', severity: 'error', summary: 'Error', detail: error });
                    
        }
      );
  }

  public getDepartments(): void {

    this.departmentService.getDepartments()
      .subscribe((res: any) => {
        this.departments = res as Department[];
      },
        (error: any) => {

          this.messageService.add({ key: 'issuetypekey', severity: 'error', summary: 'Error', detail: error });

        }
      );
  }

  public loadColumns(): void {

    this.cols = [
      { field: 'id', header: 'Id' },
      { field: 'description', header: 'Name' },
      { field: 'inactive', header: 'Inactive' },
      { field: 'orderby', header: 'Order By' },
      { field: 'departmentName', header: 'Department' }
    ];
  }

  public dialogAddNewOrEdit(id: number, action: string): void {

    switch (action) {
      case 'addnew':
        this.dialogHeaderText = 'Add New';
        this.issueType = { id: 0, inactive: false } as IssueType;
        this.department = { id: 0 } as Department;
        this.displayDialog = true;
        this.IsAddNew = true;

        break;
      case 'edit':
        this.dialogHeaderText = 'Edit';
        this.getIssueType(id);
        this.displayDialog = true;
        this.IsAddNew = false;

        break;
      default:

        break;
    }
  }

  public getIssueType(id: any): void {

    this.issueTypeService.getIssueType(id)
      .subscribe(res => {
        this.issueType = res as IssueType;
        this.getDepartment(this.issueType.departmentId);
      },
        (error: any) => {

          this.messageService.add({ key: 'issuetypekey', severity: 'error', summary: 'Error', detail: error });

        });
  }

  public getDepartment(id: number): void {

    this.departmentService.getDepartment(id)
      .subscribe(res => this.department = res as Department,
        (error: any) => {

          this.messageService.add({ key: 'issuetypekey', severity: 'error', summary: 'Error', detail: error });

        }
      );
  }

  public onSubmit(): void {

    this.loadingSubmit(true);
    this.issueType.departmentId = this.department.id;

    if (this.IsAddNew === true) {

      this.issueTypeService.addIssueType(this.issueType)
        .subscribe((data: any) => {

          this.loadingSubmit(false);
          this.displayDialog = false;
          this.messageService.add({ key: 'issuetypekey', severity: 'success', summary: 'Issue Type', detail: 'The record added successfully.' });
          this.getIssueTypes();
        },
          (error: any) => {

            this.loadingSubmit(false);

            this.messageService.add({ key: 'issuetypekey', severity: 'error', summary: 'Error', detail: error });
          });
    }
    else {

      this.issueTypeService.editIssueType(this.issueType)
        .subscribe((res: any) => {

          this.loadingSubmit(false);
          this.displayDialog = false;
          this.messageService.add({ key: 'issuetypekey', severity: 'success', summary: 'Issue Type', detail: 'The record updated successfully.' });
          this.getIssueTypes();
        },
          (error: any) => {

            this.loadingSubmit(false);

            this.messageService.add({ key: 'issuetypekey', severity: 'error', summary: 'Error', detail: error });
          });
    }
  }

  public showDeleteConf(id: number): void {

    this.issueTypeId = id;
    this.deleteModal = true;
  }

  public onDelete(): void {

    this.deleteModal = false;

    this.issueTypeService.deleteIssueType(this.issueTypeId)
      .subscribe((res: any) => {

        this.messageService.add({ key: 'issuetypekey', severity: 'success', summary: 'Delete', detail: 'Record deleted successfully' });

        this.getIssueTypes();
      },
        (error: any) => {

          this.messageService.add({ key: 'issuetypekey', severity: 'error', summary: 'Error', detail: error });

        });
  }

  public backClicked(): void {

    this._location.back();
  }

  public loadingSubmit(value: boolean): void {

    if (value === true) {
      this.loading = true;
    }
    else {
      this.loading = false;
    }
  }

}
