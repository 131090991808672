import { Component } from '@angular/core';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html'
})

export class ClientComponent {
  constructor() { }
}
