//Angular
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

//Services
import { CsrService } from '../csr.service';
import { AccountService } from '../../account/account.service';
import { DepartmentService } from '../../csr/department/department.service';
import { AppBreadcrumbService } from '../../menu/app.breadcrumb.service';

//Core
import { User } from '../../account/model/user';
import { Csr } from '../../csr/csr';
import { Department } from '../../csr/department/department';
import { Client } from '../../client/client';
import { RequestAuthorizationDate } from '../csr-details/requestAuthorizationDate';

//Primeng
import { MessageService, MenuItem } from 'primeng/api';

@Component({
  selector: 'app-csr-details',
  templateUrl: './csr-details.component.html'
})
export class CsrDetailsComponent implements OnInit {

  public title = 'CSR Number: ';
  public id = 0;
  public issue: Csr;
  public department: Department;
  public client: Client;
  public assignedTo: User;
  public issueBy: User;
  public departmentId = 0;
  public ProgramTitle = 'Program';
  public items: MenuItem[];
  private users: User[];
  private requestAuthorizationFrom: User;
  public Sending = true;
  private authorizationDate = "";
  private requestAuthorizationDate: RequestAuthorizationDate;

  constructor(private readonly csrService: CsrService,
    private readonly accountService: AccountService,
    private readonly departmentService: DepartmentService,
    public readonly breadcrumbService: AppBreadcrumbService,
    private readonly messageService: MessageService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly _location: Location,) {

    this.breadcrumbService.getMenuItems().subscribe(response => {

      this.items = response;
    });    
  }

  public ngOnInit(): void {

    if (this.isUserLoggedIn()) {

      this.id = this.route.snapshot.params['id'];
      this.issue = { id: this.id } as Csr;      
      this.department = { id: 0, name: '', description: '', inactive: false } as Department;
      this.client = {} as Client;
      this.assignedTo = {} as User;
      this.issueBy = {} as User;
      this.getIssue();
      this.title = `CSR Number: ${this.issue.id}`;

      const filterMenu = this.items.filter(a => a.label === 'details');

      if (filterMenu.length === 0) {

        const newItem = { label: 'details', routerLink: `/csr/csr-details/${this.id}` } as MenuItem;

        this.items.push(newItem);

        this.breadcrumbService.setItems(this.items);
      }
      
    }
    else {
      this.router.navigate(['/account/login']);
    }
  }

  public getIssue(): void {

    this.csrService.getCsr(this.id)
      .subscribe((res: any) => this.issue = res as Csr,
        (error: any) => {

          this.messageService.add({ key: 'csrdetailskey', severity: 'error', summary: 'Error', detail: error });

        },
        () => {          
          if (this.issue.assignedTo === null) {
            this.issue.assignedTo = 'User Undefined *';
          }
          else {
            this.getAssignedTo(this.issue.assignedTo);
          }
          this.departmentId = this.issue.departmentId;
          this.getDepartment();
          this.getUser(this.issue.issueBy);
          this.getUsers();
          //if (this.issue.issueType === 'Corrupted DB') {
          //  this.hombreGritandoSound();
          //}
        });
  }

  //public hombreGritandoSound() {
  //  let audio = new Audio();
  //  audio.src = '/assets/hombre-gritando.mp3';
  //  audio.load();
  //  audio.play();
  //}

  public getAssignedTo(userid: string): void {

    this.accountService.getUser(userid)
      .subscribe((res: any) => this.assignedTo = res as User,
        (error: any) => {

          this.messageService.add({ key: 'csrdetailskey', severity: 'error', summary: 'Error', detail: error });
        },
        () => {
          this.issue.assignedTo = this.assignedTo.name;
        }
      );
  }

  public getUsers(): void {

    this.accountService.getUsers(2)
      .subscribe((res: any) => {
        this.users = res as User[];
        this.Sending = false;
      },
        (error: any) => {

          this.messageService.add({ key: 'csreditkey', severity: 'error', summary: 'Error', detail: error });
        });
  }

  public getDepartment(): void {

    this.departmentService.getDepartment(this.departmentId)
      .subscribe((res: any) => this.department = res as Department,
        (error: any) => {

          this.messageService.add({ key: 'csrdetailskey', severity: 'error', summary: 'Error', detail: error });

        },
        () => {

          if (this.department.name === 'LWH Software/Hardware') {
            this.ProgramTitle = 'Program';
          }
          else if (this.department.name === 'Laboratory Instrumentation') {
            this.ProgramTitle = 'Instrument';
          }
          else if (this.department.name === 'Building Facility & Maintenance') {
            this.ProgramTitle = 'Location';
          }
          else if (this.department.name === 'Phone and Outbound Networks') {
            this.ProgramTitle = 'Location';
          }
          else if (this.department.name === 'Reagents Issues') {
            this.ProgramTitle = 'Department';
          }
          else {
            this.ProgramTitle = 'other department';
          }
        }
      );
  }

  public getUser(userid: string): void {

    this.accountService.getUser(userid)
      .subscribe((res: any) => this.issueBy = res as User,
        (error: any) => {

          this.messageService.add({ key: 'csrdetailskey', severity: 'error', summary: 'Error', detail: error });

        },
        () => {
          this.client = this.issueBy.client;
        }
      );
  }

  public sendTextMessage(): void {
    this.Sending = true;
    let date = new Date().toLocaleDateString('en-US')
    let time = new Date().toLocaleTimeString('en-US');
    this.authorizationDate = `${this.requestAuthorizationFrom.name} ${date} ${time}`;
    let userLogged = this.getUserLogged();
    this.requestAuthorizationDate = { userId: this.requestAuthorizationFrom.id, csrNumber: this.id, authorizationDate: this.authorizationDate, sentBy: userLogged.name } as RequestAuthorizationDate;

    this.accountService.sendTextMessage(this.requestAuthorizationDate)
      .subscribe((res: any) => {
        this.Sending = false;
        this.getIssue();
        this.messageService.add({ key: 'csrdetailskey', severity: 'success', summary: 'Service Message', detail: 'Sent successfully' });
      },
        (error: any) => {          
          this.Sending = false;
          this.messageService.add({ key: 'csrdetailskey', severity: 'error', summary: 'Error', detail: error });
        });
  }  

  public backClicked(): void {

    this._location.back();
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

  public getUserLogged(): User {

    return this.accountService.getLoggedInUser();
  }

}
