//Angular
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

//Services
import { DepartmentService } from './department.service';
import { AccountService } from '../../account/account.service';
import { AppBreadcrumbService } from '../../menu/app.breadcrumb.service';

//Core
import { Department } from './department';
import { User } from '../../account/model/user';

//Primeng
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html'
})
export class DepartmentComponent implements OnInit {

  public title = '';
  public departments: Department[] = [];
  public userDepartments: Department[] = [];
  public selectedDepartment: Department;
  public departmentId = 0;
  public userLogged: User;
  public indeterminateProgressBar = true;
  public selectNumber = {
    cero: 0,
    one: 1,
    two: 2,
    three: 3
  };

  constructor(private departmentService: DepartmentService,
    private accountService: AccountService,
    private readonly messageService: MessageService,
    private route: ActivatedRoute,
    private readonly router: Router,
    private readonly breadcrumbService: AppBreadcrumbService  ) {

    this.departments = [{ id: 0, name: '', description: '', inactive: false }];
  }

  ngOnInit(): void {

    this.selectedDepartment = { id: 0, name: '', description: '', inactive: false } as Department;

    if (this.isUserLoggedIn()) {

      this.userLogged = this.getlocalUser();

      this.route.params.subscribe(
        (params: any) => {
          this.departmentId = +params['id'];

          if (this.departmentId === this.selectNumber.one) {
            // Dashboard = 1
            this.title = 'Dashboard';
            this.getDepartmentsByUser(this.userLogged.id);
          }
          else if (this.departmentId === this.selectNumber.two) {
            // Add New CMR = 2
            this.title = 'Add New CSR';
            this.getDepartments();
          }
          else {
            // Security Events = 3
            this.title = 'Security Events';
            this.getDepartments();
          }
        });

      this.breadcrumbService.setItems([
        { label: 'csr' },
        { label: 'deparment', routerLink: [`/csr/department/${this.departmentId}`] }
      ]);

    }
    else {
      this.router.navigate(['/account/login']);
    }

  }

  public getDepartments(): void {

    this.departmentService.getDepartments()
      .subscribe((res: any) => {

        this.departments = res as Department[];        
      },
        (error: any) => {

          this.messageService.add({ key: 'departmentkey', severity: 'error', summary: 'Error', detail: error });                    
        },
        () => {

          this.indeterminateProgressBar = false;
        }
      );
  }

  public getDepartmentsByUser(userid: string): void {

    this.departmentService.getDepartmentsByUser(userid)
      .subscribe(res => this.departments = res as Department[],
        (error: any) => {

          this.messageService.add({ key: 'departmentkey', severity: 'error', summary: 'Error', detail: error });          
        },
        () => {

          this.indeterminateProgressBar = false;
        }
      );
  }

  public goToDashboardOrNewCSR(): void {

    // Dashboard = 1
    // Add New CSR = 2
    // Security Events = 3

    if (this.departmentId === this.selectNumber.one) {
      // Dashboard
      this.departmentService.setDepartmentID(this.selectedDepartment.id);
      this.router.navigate(['/csr/dashboard']);
    }
    else if (this.departmentId === this.selectNumber.two) {
      // Add New CSR
      this.router.navigate([`/csr/csr-add/0/${this.selectedDepartment.id}`]);
    }
    else {
      // Security Events
      this.router.navigate([`/csr/csr-add/1/${this.selectedDepartment.id}`]);
    }
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

  public getlocalUser(): User {

    return this.accountService.getLoggedInUser();
  }  

}
