import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from '../core/base.service';

//Core
import { Guide } from './guide';
import { GuideGroupByProjects } from '../guides/guide-group-by-projects/guide-group-by-projects';

@Injectable()
export class GuideService extends BaseService {

  public _baseUrlToAPI = 'api/guide';

  constructor(private http: HttpClient) {
    super();
  }

  public getGuide(id: number): Observable<Guide> {

    return this.http.get<Guide>(`${this._baseUrlToAPI}/guide/${id}`);
  }

  public addGuide(formData: FormData): Observable<any> {

    return this.http.post<any>(`${this._baseUrlToAPI}/uploadfile`, formData);
  }

  public getGuides(projectid: number): Observable<Guide[]> {

    return this.http.get<Guide[]>(`${this._baseUrlToAPI}/${projectid}`);
  }

  public getGuideGroupByProjects(): Observable<GuideGroupByProjects[]> {

    return this.http.get<GuideGroupByProjects[]>(`${this._baseUrlToAPI}/guide-group-by-projects`);
  }

  public downloadPdfFile(id: number): Observable<Blob> {

    return this.http.get(`${this._baseUrlToAPI}/file/${id}`, { headers: this.PutPostHeaders, responseType: 'blob' });
  }

  public deleteGuide(id: string): Observable<any> {

    return this.http.delete<any>(`${this._baseUrlToAPI}/${id}`);
  }

}
