import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
// https://www.npmjs.com/package/@aspnet/signalr   *** npm i @aspnet/signalr ****
import { HubConnectionBuilder, HubConnection, HttpTransportType } from '@aspnet/signalr';

//Component
import { AppMainComponent } from './app.main.component';

//Services
import { CsrService } from '../csr/csr.service';
import { CommentsAssignedToMyService } from '../csr/comments-assigned-to-my.service';
import { AccountService } from '../account/account.service';
import { UserLoggedInService } from '../account/user-logged-in.service';
import { DepartmentService } from '../csr/department/department.service';

//Model
import { Comment } from '../csr/comment/comment';
import { User } from '../account/model/user';
import { Csr } from '../csr/csr';
import { NewCsrsCounter } from '../csr/new-csrs-counter';
import { MessageNewCsr } from '../signalr/message-new-csr'

//Primeng
import { MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
    selector: 'app-topbar',
    template: `
          <p-toast [style]="{'white-space': 'pre-line'}" key="topbarkey"></p-toast>
          <div class="topbar clearfix">
            <div class="topbar-left" routerLink="/">
                <div class="logo"></div>
            </div>

            <div class="topbar-right">
                <a id="menu-button" href="#" (click)="app.onMenuButtonClick($event)">
                    <i class="pi pi-angle-left"></i>
                </a>

                <!--<a id="rightpanel-menu-button" href="#" (click)="app.onRightPanelButtonClick($event)">
                    <i class="pi pi-ellipsis-v"></i>
                </a>-->

                <a id="topbar-menu-button" href="#" (click)="app.onTopbarMenuButtonClick($event)">
                    <i class="pi pi-bars"></i>
                </a>

                <ul class="topbar-items animated fadeInDown" [ngClass]="{'topbar-items-visible': app.topbarMenuActive}">
                    <!--<li #profile class="profile-item" *ngIf="app.profileMode==='top'||app.isHorizontal()"
                        [ngClass]="{'active-top-menu':app.activeTopbarItem === profile}">

                        <a href="#" (click)="app.onTopbarItemClick($event,profile)">
                            <img class="profile-image" src="assets/layout/images/avatar.png" />
                            <span class="topbar-item-name">Jane Williams</span>
                        </a>

                        <ul class="ultima-menu animated fadeInDown">
                            <li role="menuitem">
                                <a href="#" (click)="app.onTopbarSubItemClick($event)">
                                    <i class="pi pi-user"></i>
                                    <span>Profile</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" (click)="app.onTopbarSubItemClick($event)">
                                    <i class="pi pi-lock"></i>
                                    <span>Privacy</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" (click)="app.onTopbarSubItemClick($event)">
                                    <i class="pi pi-cog"></i>
                                    <span>Settings</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" (click)="app.onTopbarSubItemClick($event)">
                                    <i class="pi pi-power-off"></i>
                                    <span>Logout</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li #settings [ngClass]="{'active-top-menu':app.activeTopbarItem === settings}">
                        <a href="#" (click)="app.onTopbarItemClick($event,settings)">
                            <i class="topbar-icon pi pi-cog"></i>
                            <span class="topbar-item-name">Settings</span>
                        </a>
                        <ul class="ultima-menu animated fadeInDown">
                            <li role="menuitem">
                                <a href="#" (click)="app.onTopbarSubItemClick($event)">
                                    <i class="pi pi-palette"></i>
                                    <span>Change Theme</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" (click)="app.onTopbarSubItemClick($event)">
                                    <i class="pi pi-heart"></i>
                                    <span>Favorites</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" (click)="app.onTopbarSubItemClick($event)">
                                    <i class="pi pi-lock"></i>
                                    <span>Lock Screen</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" (click)="app.onTopbarSubItemClick($event)">
                                    <i class="pi pi-image"></i>
                                    <span>Wallpaper</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li #messages [ngClass]="{'active-top-menu':app.activeTopbarItem === messages}">
                        <a href="#" (click)="app.onTopbarItemClick($event,messages)">
                            <i class="topbar-icon pi pi-envelope animated swing"></i>
                            <span class="topbar-badge animated rubberBand">5</span>
                            <span class="topbar-item-name">Messages</span>
                        </a>
                        <ul class="ultima-menu animated fadeInDown">
                            <li role="menuitem">
                                <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                                    <img src="assets/layout/images/avatar1.png" width="35"/>
                                    <span>Give me a call</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                                    <img src="assets/layout/images/avatar2.png" width="35"/>
                                    <span>Sales reports attached</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                                    <img src="assets/layout/images/avatar3.png" width="35"/>
                                    <span>About your invoice</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                                    <img src="assets/layout/images/avatar2.png" width="35"/>
                                    <span>Meeting today at 10pm</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                                    <img src="assets/layout/images/avatar4.png" width="35"/>
                                    <span>Out of office</span>
                                </a>
                            </li>
                        </ul>
                    </li>-->
                    <li *ngIf="isUserLoggedIn() && commentEmpty" #notifications [ngClass]="{'active-top-menu':app.activeTopbarItem === notifications}">
                        <a [routerLink]="" (click)="openCommentsTable($event, mycomments)">
                            <i class="topbar-icon pi pi-comments"></i>
                            <span class="topbar-badge animated rubberBand">{{commentsQty}}</span>
                            <span class="topbar-item-name">Comments</span>
                        </a>
                        <ul class="ultima-menu animated fadeInDown">
                            <!--<li role="menuitem">
                                <a href="#" (click)="openCommentsTable($event, mycomments)">
                                    <i class="pi pi-comments"></i>
                                    <span>New comment(s)</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" (click)="app.onTopbarSubItemClick($event)">
                                    <i class="pi pi-calendar"></i>
                                    <span>Meeting today at 3pm</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" (click)="app.onTopbarSubItemClick($event)">
                                    <i class="pi pi-download"></i>
                                    <span>Download documents</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" (click)="app.onTopbarSubItemClick($event)">
                                    <i class="pi pi-ticket"></i>
                                    <span>Book flight</span>
                                </a>
                            </li>-->
                        </ul>
                    </li>
                    <li #search *ngIf="isUserLoggedIn() && isAdmin" class="search-item" [ngClass]="{'active-top-menu':app.activeTopbarItem === search}"
                        (click)="app.onTopbarItemClick($event,search)">
                        <span class="p-input-icon-left">
                          <div class="p-grid">
                            <div class="p-col-9">
                                <!--<i class="topbar-icon pi pi-search"></i>-->
                                <input id="boxSearchCsr" type="text" pInputText #boxSearchCsr (keyup.enter)="onSearchCsr(boxSearchCsr.value)" placeholder="CSR number">
                              </div>
                              <div class="p-col-2">
                                <button pButton type="button" icon="pi pi-search" class="p-button-info p-ml-2" (click)="onSearchCsr(boxSearchCsr.value)" pTooltip="search" tooltipPosition="top"></button>
                              </div>
                            </div>
                        </span>
                    </li>
                    <li *ngIf="isUserLoggedIn() && newCsrQty > 0" class="p-mr-4" #messages [ngClass]="{'active-top-menu':app.activeTopbarItem === messages}">
                        <a href="#" (click)="app.onTopbarItemClick($event,messages)">
                            <!--<i class="topbar-icon pi pi-bell"></i>-->
                            <img src="assets/bell.gif" width="32"/>
                            <span class="topbar-badge animated rubberBand">{{newCsrQty}}</span>
                            <span class="topbar-item-name">New order(s)</span>
                        </a>
                        <ul class="ultima-menu animated fadeInDown">
                          <li role="menuitem" *ngFor="let newCsr of newCsrsCounters">
                            <a class="topbar-message" [routerLink]="" (click)="goToNewCSRs(newCsr.departmentId)">                              
                                <span>({{newCsr.quantity}}) {{newCsr.departmentName}}</span>
                            </a>
                          </li>
                      </ul>
                    </li>
                </ul>
            </div>
        </div>
<p-overlayPanel #mycomments [showCloseIcon]="true" [style]="{width: '650px'}">
  <ng-template pTemplate>
    <p-table #dt [columns]="cols"
             [value]="comments"
             [paginator]="true" [rows]="5"
             [responsive]="true"
             [loading]="loading">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngClass]="(col.header === 'CSR #' ? 'col-small' : null) || (col.header === 'Comment #' ? 'col-small' : null) || (col.header === 'Date' ? 'col-small' : null)">
            {{col.header}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
          <th class="col-button"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
          <td *ngFor="let col of columns">
            <div *ngIf="col.field !== 'commentDate'">
              {{rowData[col.field]}}
            </div>
            <div *ngIf="col.field === 'commentDate'">
              {{rowData[col.field] | date: 'shortDate'}}
            </div>
          </td>
          <td class="col-button">
            <button pButton type="button" icon="pi pi-search" (click)="selectCsr(rowData.issueId, mycomments)"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-template>
</p-overlayPanel>`
})
export class AppTopbarComponent implements OnInit {

  public comments: Comment[] = [];
  public userLogged: User = {} as User;
  public newCsrsCounters: NewCsrsCounter[] = [];
  public newCsrQty = 0;
  public commentEmpty = false;
  public commentsQty = 0;
  public cols: any[];
  public loading: boolean;
  public issue: Csr;
  public isAdmin = false;
  @ViewChild('boxSearchCsr') boxSearchCsr: ElementRef;
  public messageNewCsr: MessageNewCsr = {} as MessageNewCsr;
  public hubConnection!: HubConnection;

  constructor(public app: AppMainComponent,
    private readonly accountService: AccountService,
    private readonly csrService: CsrService,
    private readonly commentsAssignedToMyService: CommentsAssignedToMyService,
    private readonly departmentService: DepartmentService,
    private readonly router: Router,
    private readonly userLoggedInService: UserLoggedInService,
    private readonly messageService: MessageService  ) { }

  public ngOnInit(): void {

    this.userLogged = this.getUser();
    this.updateCommentsByService();
    this.getComments();
    this.getNewCSRs();
    this.listenerNewCsr();
  }

  public listenerNewCsr() {

    let builder = new HubConnectionBuilder();
    //this.hubConnection = builder.withUrl("http://localhost:57232/newcsr",
    this.hubConnection = builder.withUrl("https://cmr.labwarehousepr.com/newcsr",
      {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      }).build();

    this.hubConnection.start()
      .then(() => console.log("Connection started"))
      .catch(error => console.log(error));

    this.hubConnection.on("BroadcastMessage", (message) => {

      this.messageNewCsr = message;
      
      if (this.messageNewCsr.actionType === 'delete') {
        this.bellRingSound();
        //this.messageService.add({ key: 'topbarkey', severity: 'error', summary: 'Deleted Csr', detail: `Csr #. ${this.messageNewCsr.csrId}, ${this.messageNewCsr.subject}` });
        setTimeout(() => {
          this.messageService.add({ key: 'topbarkey', severity: 'error', summary: 'Deleted Csr', detail: `Csr #. ${this.messageNewCsr.csrId}, ${this.messageNewCsr.subject}`, life: 20000 });
        }, 1000);
      } else if (this.messageNewCsr.actionType === 'put') {
        this.bellRingSound();
        let arrays: string[] = [`Csr #. ${this.messageNewCsr.csrId}`, `Subject: ${this.messageNewCsr.subject}`, `Project: ${this.messageNewCsr.projectName}`, `${this.messageNewCsr.clientName}`];
        //this.messageService.add({ key: 'topbarkey', severity: 'info', summary: 'Updated Csr', detail: arrays.join('\n') });
        setTimeout(() => {
          this.messageService.add({ key: 'topbarkey', severity: 'info', summary: 'Updated Csr', detail: arrays.join('\n'), life: 20000 });
        }, 1000);
      } else {
        this.newCsrSound();
        let arrays: string[] = [`Csr #. ${this.messageNewCsr.csrId}`, `Subject: ${this.messageNewCsr.subject}`, `Project: ${this.messageNewCsr.projectName}`, `Client: ${this.messageNewCsr.clientName}`];
        //this.messageService.add({ key: 'topbarkey', severity: 'success', summary: 'New Csr', detail: arrays.join('\n') });
        setTimeout(() => {
          this.messageService.add({ key: 'topbarkey', severity: 'success', summary: 'New Csr', detail: arrays.join('\n'), life: 25000 });
        }, 1000);
      }

      this.getNewCSRs();
    });

  }

  public newCsrSound() {
    let audio = new Audio();
    audio.src = '/assets/you-have-a-new-csr.mp3';
    audio.load();
    audio.play();
  }

  public bellRingSound() {
    let audio = new Audio();
    audio.src = '/assets/bell-ring.mp3';
    audio.load();
    audio.play();
  }

  public openCommentsTable(event: any, overlaypanel: OverlayPanel): void {

    overlaypanel.toggle(event);
  }

  public selectCsr(issueid: number, overlaypanel: OverlayPanel): void {

    overlaypanel.hide();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate([`/csr/csr-details/${issueid}`]);
  }

  public getComments(): void {

    if (this.isUserLoggedIn()) {

      this.csrService.getCommentsAssignedToMy()
        .subscribe((res: any) => {

          this.comments = res as Comment[];

          if (this.comments.length > 0) {
            this.commentEmpty = true;
            this.commentsQty = this.comments.length;
          }
          else {
            this.commentEmpty = false;
            this.commentsQty = 0;
          }

          this.loadColumns();

          this.loading = false;

          this.commentsAssignedToMyService.updateComments(this.comments);
        },
          (error: any) => {

            this.messageService.add({ key: 'topbarkey', severity: 'error', summary: 'Error', detail: error });

          });
    }
  }

  public updateCommentsByService(): void {

    this.commentsAssignedToMyService.scast.subscribe(comments => {

      this.comments = comments;

      if (this.comments.length > 0) {
        this.commentEmpty = true;
        this.commentsQty = this.comments.length;
      }
      else {
        this.commentEmpty = false;
        this.commentsQty = 0;
      }

      this.loadColumns();

      this.loading = false;
    });

  }

  public loadColumns(): void {

    this.cols = [
      { field: 'issueId', header: 'CSR #' },
      { field: 'id', header: 'Comment #' },
      { field: 'commentDate', header: 'Date' },
      { field: 'commentBy', header: 'Comment by' }
    ];

    this.loading = true;
  }

  public onSearchCsr(value: string): void {

    if (!Number(value)) {
      this.boxSearchCsr.nativeElement.value = '';
      this.messageService.add({ key: 'topbarkey', severity: 'error', summary: 'Error', detail: 'Please enter only numbers.' });
    } else {
      this.csrService.getCsr(+value)
        .subscribe((res: any) => this.issue = res as Csr,
          (error: any) => {

            this.boxSearchCsr.nativeElement.value = '';
            this.messageService.add({ key: 'topbarkey', severity: 'warn', summary: 'Not found', detail: error });
          },
          () => {

            this.boxSearchCsr.nativeElement.value = '';

            if (this.issue !== undefined) {         
              this.redirectTo(`/csr/csr-details/${this.issue.id}`);
            }

          });
    }
  }

  public redirectTo(uri: string): void {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  public goToNewCSRs(departmentId: number): void {
    this.departmentService.setDepartmentID(departmentId);
    this.redirectTo('/csr/quick-search/1/0');
  }

  public getNewCSRs(): void {

    if (this.isUserLoggedIn()) {

      this.csrService.getNewCsrs(this.userLogged.id)
        .subscribe((res: any) => {

          this.newCsrsCounters = res as NewCsrsCounter[];          
        },
          (error: any) => {

            this.messageService.add({ key: 'topbarkey', severity: 'error', summary: 'Error', detail: error });

          },
          () => {
            this.newCsrQty = 0;
            for (var csr of this.newCsrsCounters) {
              this.newCsrQty += csr.quantity;
            }

          });
    }
  }

  public getUser(): User {

    let userLogged = this.accountService.getLoggedInUser();

    const userRoles = userLogged.roles.find(row => row === 'Admin' || row === 'SuperAdmin');
    if (userRoles !== undefined) {
      this.isAdmin = true;
    }
    else {
      this.isAdmin = false;
    }

    return userLogged;
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

}
