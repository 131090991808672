//Angular
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

//Services
import { ClientsService } from '../../client/client.service';
import { AccountService } from '../account.service';

//Core
import { Client } from '../../client/client';
import { Registration } from '../model/registration';

//Primeng
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html'
})
export class RegisterComponent implements OnInit {

  public title = 'Register';
  public newUser: Registration;
  public clients: Client[];
  public selectedClient: Client;
  public FormIsValid = true;
  public loading = false;

  constructor(private readonly accountService: AccountService,
    private readonly clientsService: ClientsService,
    private readonly messageService: MessageService,
    private readonly router: Router) {
  }

  public ngOnInit(): void {

    this.newUser = {} as Registration;
    this.getClients();
  }

  public getClients(): void {

    this.clientsService.getClients()
      .subscribe((res: any) => this.clients = res as Client[],
        (error: any) => {

          this.messageService.add({ key: 'registerkey', severity: 'error', summary: 'Error', detail: error });

          if (error === 'Unauthorized') {
            this.router.navigate(['/account/login']);
          }

        }
      );
  }

  public register(): void {

    if (this.newUser.password !== this.newUser.confirmPassword) {

      this.FormIsValid = false;

      this.messageService.add({ key: 'registerkey', severity: 'error', summary: 'Error', detail: 'Password and confirm password must match.' });
    }
    else {

      this.FormIsValid = true;
      this.newUser.clientid = this.selectedClient.id.toString();

      this.accountService.register(this.newUser)
        .subscribe((res: any) => {

          this.messageService.add({ key: 'registerkey', severity: 'success', summary: 'Register', detail: 'Register success.' });
          this.router.navigate(['account/login']);          
        },
          (error: any) => {

            this.messageService.add({ key: 'registerkey', severity: 'error', summary: 'Error', detail: error });

          });
    }
  }

}
