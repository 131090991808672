import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from '../../core/base.service';

//Core
import { Image } from './image';

@Injectable()
export class ImageService extends BaseService {

  public _baseUrlToAPI = 'api/image';

  constructor(private readonly http: HttpClient) {
    super();
  }

  public getImage(id: number): Observable<Image[]> {

    return this.http.get<Image[]>(`${this._baseUrlToAPI}/${id}`);
  }

  public uploadImage(formData: FormData): Observable<any> {
    
    return this.http.post<any>(`${this._baseUrlToAPI}/upload`, formData);
  }

  public downloadPdfFile(id: number): Observable<Blob> {

    return this.http.get(`${this._baseUrlToAPI}/file/${id}`, { headers: this.PutPostHeaders, responseType: 'blob' });
  }

  public deleteImage(id: number): Observable<any> {

    return this.http.delete<any>(`${this._baseUrlToAPI}/${id}`);
  }  

}
