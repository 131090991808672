//Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

//Services
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { CsrService } from '../csr.service';
import { AccountService } from '../../account/account.service';
import { DepartmentService } from '../../csr/department/department.service';
import { CommentsAssignedToMyService } from '../../csr/comments-assigned-to-my.service';
import { AppBreadcrumbService } from '../../menu/app.breadcrumb.service';

//Core
import { Csr } from '../csr';
import { DashboardData } from './dashboard-data';
import { Department } from '../../csr/department/department';
import { Icsr } from './icsr';
import { User } from '../../account/model/user';

//Primeng
import { MessageService } from 'primeng/api';

/* tslint:disable:no-import-side-effect ordered-imports */
//images
import '../../../assets/dashboard-client-icon.png';
import '../../../assets/dashboard-cmr-icon.png';
import '../../../assets/dashboard-guide-icon.png';
import '../../../assets/dashboard-project-icon.png';
import '../../../assets/dashboard-user-icon.png';
import '../../../assets/shield.png';
import '../../../assets/dashboard-waiting-client.png';
import '../../../assets/dashboard-new-request.png';
import '../../../assets/dashboard-in-progress.png';
import '../../../assets/dashboard-special-projects.png';
import '../../../assets/dashboard-holding-administrative-clearance.png';
import '../../../assets/dashboard-rejected.png';
import '../../../assets/dashboard-not-completed.png';
import '../../../assets/missingphoto.png';
import '../../../assets/issuetype.png';
import '../../../assets/pin.png';
import '../../../assets/unpin.png';
/* tslint:enable:no-import-side-effect */

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  styles: []
})
export class DashboardComponent implements OnInit, OnDestroy {

  public title = 'Dashboard';
  public dashboardData: DashboardData[];
  public userLogged: User;
  public departmentId = 0;
  public department: Department;
  public indeterminateProgressBar = true;
  public progressBar = false;
  public csrSearchBySubjectResult: Icsr[];
  public selectedCSRbySubject: Icsr | undefined;
  public commentEmpty = false;
  public commentsQty = 0;

  constructor(private readonly accountService: AccountService,
    private readonly csrService: CsrService,    
    private readonly departmentService: DepartmentService,
    private readonly commentsAssignedToMyService: CommentsAssignedToMyService,
    private readonly messageService: MessageService,
    private readonly router: Router,
    private readonly breadcrumbService: AppBreadcrumbService) {

    //this.dashboardData = [
    //  { status: 'New request', statusCount: 0, orderBy: 1, urlLink: '/csr/quick-search/1', image: '/assets/dashboard-new-request.png', unReadComments: 0 },
    //  { status: 'In Progress', statusCount: 0, orderBy: 2, urlLink: '/csr/quick-search/2', image: '/assets/dashboard-in-progress.png', unReadComments: 0 },
    //  { status: 'Completed', statusCount: 0, orderBy: 3, urlLink: '/csr/quick-search/3', image: '/assets/dashboard-cmr-icon.png', unReadComments: 0 },
    //  { status: 'Waiting for additional information', statusCount: 0, orderBy: 4, urlLink: '/csr/quick-search/4', image: '/assets/dashboard-waiting-client.png', unReadComments: 0 },
    //  { status: 'Special projects', statusCount: 0, orderBy: 5, urlLink: '/csr/quick-search/5', image: '/assets/dashboard-special-projects.png', unReadComments: 0 },
    //  { status: 'Not Completed', statusCount: 0, orderBy: 6, urlLink: '/csr/quick-search/6', image: '/assets/dashboard-not-completed.png', unReadComments: 0 },
    //  { status: 'Rejected', statusCount: 0, orderBy: 7, urlLink: '/csr/quick-search/7', image: '/assets/dashboard-rejected.png', unReadComments: 0 },
    //  { status: 'Holding Administrative Clearance', statusCount: 0, orderBy: 8, urlLink: '/csr/quick-search/8', image: '/assets/dashboard-holding-administrative-clearance.png', unReadComments: 0 },
    //  { status: 'Security events log', statusCount: 0, orderBy: 9, urlLink: '/csr/quick-search/9', image: '/assets/shield.png', unReadComments: 0 },
    //  { status: 'Projects', statusCount: 0, orderBy: 10, urlLink: '/project/projects', image: '/assets/dashboard-project-icon.png', unReadComments: 0 },
    //  { status: 'Clients', statusCount: 0, orderBy: 11, urlLink: '/client/clients', image: '/assets/dashboard-client-icon.png', unReadComments: 0 },
    //  { status: 'Implementation Guides', statusCount: 0, orderBy: 12, urlLink: '/guide/guide-add', image: '/assets/dashboard-guide-icon.png', unReadComments: 0 },
    //  { status: 'Users', statusCount: 0, orderBy: 13, urlLink: '/account/users', image: '/assets/dashboard-user-icon.png', unReadComments: 0 },
    //  { status: 'Issue Type', statusCount: 0, orderBy: 14, urlLink: '/csr/issue-type', image: '/assets/issuetype.png', unReadComments: 0 }
    //];

    //this.indeterminateProgressBar = false;
    //this.blockedPanel = false;
  }

  public ngOnInit(): void {

    this.departmentId = this.departmentService.getDepartmentID();

    if (this.isUserLoggedIn()) {

      this.breadcrumbService.setItems([
        { label: 'csr' },
        { label: 'deparment', routerLink: [`/csr/department/${this.departmentId}`] },
        { label: 'dashboard', routerLink: ['/csr/dashboard'] }
      ]);

      this.userLogged = this.getlocalUser();      
      this.getCMRs();

    } else {

      this.indeterminateProgressBar = false;
      this.router.navigate(['/account/login']);

    }
  }

  public ngOnDestroy(): void {
  }
    
  public getCMRs(): void {

    if (this.departmentId === 0) {
      this.router.navigate(['/cmr/department', 1]);
    }

    this.csrService.getCsrsPerDepartment(this.departmentId)
      .subscribe((res: any) => this.dashboardData = res as DashboardData[],
        (error: any) => {
          this.indeterminateProgressBar = false;

          this.messageService.add({ key: 'dashboardkey', severity: 'error', summary: 'Error', detail: error });
                             
        },
        () => {
          this.indeterminateProgressBar = false;
          this.getDepartment();
        });
  }

  public getDepartment(): void {

    this.departmentService.getDepartment(this.departmentId)
      .subscribe((res: any) => {
        this.department = res as Department;
        this.title = `Dashboard [${this.department.name}]`;
        this.checkCommentsByService();
        //this.getCMRs();
      },
        (error: any) => {

          this.messageService.add({ key: 'dashboardkey', severity: 'error', summary: 'Error', detail: error });

        });
  }

  public fileChangeEvent(fileInput: any): void {
    this.onUpload(fileInput.files[0]);
  }

  public onUpload(file: any): void {

    if (this.isUserLoggedIn()) {

      this.progressBar = true;

      const formData = new FormData();

      formData.append(file.name, file);
      formData.append('userid', this.userLogged.id);
      
      this.accountService.uploadAvatar(formData)
        .subscribe((res: any) => {

          let resultUser = res as User;

          this.getUser(resultUser.id);

          this.messageService.add({ key: 'dashboardkey', severity: 'success', summary: 'Avatar', detail: 'File upload successful' });                    
        },
        (error: any) => {

          this.messageService.add({ key: 'dashboardkey', severity: 'error', summary: 'Error', detail: error });

        });
    }
  }

  public goToFilterComments(url: string): void {

    this.router.navigate([`${url}/1`]);
  }

  public searchCSRbySubject(event: any): void {

    this.csrService.searchCsrBySubject(event.query)
      .subscribe((data) => {

        this.csrSearchBySubjectResult = data;
      });
  }

  public onCSRSelection(icsr: Icsr): void {

    const link = `/csr/csr-details/${icsr.id}`;

    this.router.navigate([]).then(result => {
      window.open(link, '_blank');
    });
  }

  public onClearSelectedCSR(): void {

    this.selectedCSRbySubject = undefined;
  }

  public checkCommentsByService(): void {

    this.commentsAssignedToMyService.scast.subscribe(comments => {

      if (comments.length > 0) {
        this.commentEmpty = true;
        this.commentsQty = comments.length;
      }
      else {
        this.commentEmpty = false;
        this.commentsQty = 0;
      }
    });

  }

  public getUser(id: string): void {

    this.accountService.getUser(id)
      .subscribe((res: any) => this.userLogged = res as User,
        (error: any) => {

          this.messageService.add({ key: 'dashboardkey', severity: 'error', summary: 'Error', detail: error });

        });
  }

  public isMobile() {
    return window.innerWidth <= 640;
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

  public getlocalUser(): User {

    let userLogged = this.accountService.getLoggedInUser();

    if (userLogged == undefined) {
      this.router.navigate(['/account/login'])
    }

    return userLogged;
  }

}
