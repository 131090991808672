//Angular
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

//Services
import { CsrService } from '../csr.service';
import { AccountService } from '../../account/account.service';
import { CommentsAssignedToMyService } from '../../csr/comments-assigned-to-my.service';
import { DepartmentService } from '../../csr/department/department.service';
import { IssueTypeService } from '../../csr/issue-type/issue-type.service';
import { ProjectService } from '../../project/project.service';

//Core
import { Csr } from '../csr';
import { Comment } from '../comment/comment';
import { Department } from '../../csr/department/department';
import { IssueType } from '../../csr/issue-type/issue-type';
import { Project } from '../../project/project';
import { User } from '../../account/model/user';

//Primeng
import { MessageService } from 'primeng/api';

interface ObjectProperties {
  label: string;
  value: string;
}

@Component({
  selector: 'app-csr-edit',
  templateUrl: './csr-edit.component.html'
})
export class CsrEditComponent implements OnInit {

  public title = 'Edit CSR: ';
  public issue: Csr;
  public issueId = 0;
  public projects: Project[];
  public project: Project;
  public department: Department;
  public departments: Department[] = [];
  public assignedto: User;
  public selectPriority: ObjectProperties;
  public priorities: ObjectProperties[];
  public selectStatus: ObjectProperties;
  public statuslist: ObjectProperties[];
  public users: User[];
  public ProgramTitle = 'Program';
  public issuetypes: IssueType[] = [];
  public selectIssuetypes: IssueType;
  public Sending = false;
  public additionalData: string[] = [];
  public showAdditionalData = false;
  public showForm = false;
  public commentsAssignedToMy: Comment[] = [];
  public inprogressdateValidator = false;
  public completeddateValidator = false;
  @ViewChild('issueform', { static: false }) public issueForm: NgForm;
  public processIcon = false;

  constructor(private readonly csrService: CsrService,
    private readonly accountService: AccountService,
    private readonly projectService: ProjectService,
    private readonly departmentService: DepartmentService,
    private readonly issueTypeService: IssueTypeService,
    private readonly commentsAssignedToMyService: CommentsAssignedToMyService,
    private readonly messageService: MessageService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,) {

    this.statuslist = [
      { label: 'New request', value: 'New request' },
      { label: 'In Progress', value: 'In Progress' },
      { label: 'Completed', value: 'Completed' },
      { label: 'Waiting for additional information', value: 'Waiting for additional information' },
      { label: 'Special projects', value: 'Special projects' },
      { label: 'Not Completed', value: 'Not Completed' },
      { label: 'Rejected', value: 'Rejected' },
      { label: 'Holding Administrative Clearance', value: 'Holding Administrative Clearance' },
      { label: 'Security events log', value: 'Security events log' }
    ];
    this.priorities = [
      { label: 'Low', value: 'Low' },
      { label: 'Medium', value: 'Medium' },
      { label: 'High', value: 'High' }
    ];

  }

  public ngOnInit(): void {

    if (this.isUserLoggedIn()) {

      const id = this.route.snapshot.params['id'];
      this.issue = {} as Csr;
      this.project = {} as Project;
      this.department = {} as Department;
      this.assignedto = { id: '0' } as User;
      this.getCsr(id);
    }
    else {
      this.router.navigate(['/account/login']);
    }
  }

  public getCsr(id: number): void {

    this.csrService.getCsr(id)
      .subscribe((res: any) => {

        this.issue = res as Csr;

        this.getProjects(this.issue.departmentId);
        this.getUsers();
        this.getDepartments();
        this.selectPriority = this.priorities.filter((x) => x.value === this.issue.priority)[0];
        this.selectStatus = this.statuslist.filter((x) => x.value === this.issue.status)[0];        

        // Para que no de error el primeng p-calendar.
        if (this.issue.dateSubmitted !== null) {
          this.issue.dateSubmitted = new Date(this.issue.dateSubmitted);
        }
        if (this.issue.inProgressDate !== null) {
          this.issue.inProgressDate = new Date(this.issue.inProgressDate);
        }
        if (this.issue.completedDate !== null) {
          this.issue.completedDate = new Date(this.issue.completedDate);
        }
        if (this.issue.scheduleDate !== null) {
          this.issue.scheduleDate = new Date(this.issue.scheduleDate);
        } else {
          this.issue.scheduleDate = new Date();
        }
      },
        (error: any) => {

          this.messageService.add({ key: 'csreditkey', severity: 'error', summary: 'Error', detail: error });                    
        });
  }  

  public getProjects(departmentId: number): void {

    this.projectService.getProjectsPerDepartment(departmentId)
      .subscribe((res: any) => {
        this.projects = res as Project[];

        this.project = this.projects.filter((x) => x.id === this.issue.projectId)[0];
      },
        (error: any) => {

          this.messageService.add({ key: 'csreditkey', severity: 'error', summary: 'Error', detail: error });

        }
      );
  }

  public getUsers(): void {

    this.processIcon = true;

    this.accountService.getShortUsersList()
      .subscribe((res: any) => {
        this.users = res as User[];
        this.processIcon = false;
        this.assignedto = this.users.filter((x) => x.id === this.issue.assignedTo)[0];
      },
        (error: any) => {
          this.processIcon = false;
          this.messageService.add({ key: 'csreditkey', severity: 'error', summary: 'Error', detail: error });
        });
  }

  public getDepartments(): void {

    this.departmentService.getDepartments()
      .subscribe((res: any) => {
        this.departments = res as Department[];

        this.department = this.departments.filter((x) => x.id === this.issue.departmentId)[0];

        this.getIssueTypesPerDepartment();

        if (this.department.name === 'LWH Software/Hardware') {
          this.ProgramTitle = 'Program';
        }
        else if (this.department.name === 'Laboratory Instrumentation') {
          this.ProgramTitle = 'Instrument';
        }
        else if (this.department.name === 'Building Facility & Maintenance') {
          this.ProgramTitle = 'Location';
        }
        else if (this.department.name === 'Phone and Outbound Networks') {
          this.ProgramTitle = 'Location';
        }
        else if (this.department.name === 'Reagents Issues') {
          this.ProgramTitle = 'Department';
        }
        else {
          this.ProgramTitle = 'other department';
        }
      },
        (error: any) => {

          this.messageService.add({ key: 'csreditkey', severity: 'error', summary: 'Error', detail: error });

        }
      );
  }

  public getIssueTypesPerDepartment(): void {

    this.issueTypeService.getIssueTypesPerDepartment(this.department.id)
      .subscribe(res => {
        this.issuetypes = res as IssueType[];

        this.selectIssuetypes = this.issuetypes.filter((x) => x.description === this.issue.issueType)[0];
      },
        (error: any) => {

          this.messageService.add({ key: 'csreditkey', severity: 'error', summary: 'Error', detail: error });

        });
  }

  public onSubmit(): void {
    
    if (this.isUserLoggedIn()) {

      if (this.issue.inProgressDate === null) {
        this.issue.inProgressDate = new Date('2000, 1, 1');
      } else {
        this.issue.strInProgressDate = `${this.issue.inProgressDate.toLocaleDateString('en-US')} ${this.issue.inProgressDate.toLocaleTimeString('en-US')}`; //this.issue.inProgressDate.toLocaleString();
      }

      if (this.issue.completedDate === null) {
        this.issue.completedDate = new Date('2000, 1, 1');
      } else {
        this.issue.strCompletedDate = `${this.issue.completedDate.toLocaleDateString('en-US')} ${this.issue.completedDate.toLocaleTimeString('en-US')}`; //this.issue.completedDate.toLocaleString();
      }

      if (this.issue.scheduleDate === null) {
        this.issue.scheduleDate = new Date('2000, 1, 1');
      } else {
        this.issue.strScheduleDate = `${this.issue.scheduleDate.toLocaleDateString('en-US')} ${this.issue.scheduleDate.toLocaleTimeString('en-US')}`; //this.issue.completedDate.toLocaleString();
      }

      if (this.additionalData.length > 0) {
        this.issue.additionalData = this.additionalData;
      }

      this.issue.projectId = this.project.id;
      this.issue.project = this.project;
      if (this.assignedto !== undefined) {
        this.issue.assignedTo = this.assignedto.id.toString();
      }
      this.issue.priority = this.selectPriority.value;
      this.issue.issueType = this.selectIssuetypes.description;
      this.issue.status = this.selectStatus.value;
      this.issue.departmentId = this.department.id;

      this.sendingEMail(true);

      this.csrService.editCsr(this.issue)
        .subscribe((res: any) => {
          this.issueId = res as number;
          this.sendingEMail(false);
          this.getComments();
          this.router.navigate([`/csr/csr-details/${this.issueId}`]);  
        },
          (error: any) => {            

            this.sendingEMail(false);

            this.messageService.add({ key: 'csreditkey', severity: 'error', summary: 'Error', detail: error });
          });
    }
    else {
      this.router.navigate(['/account/login']);
    }
  }

  public sendingEMail(value: boolean): void {

    if (value === true) {
      this.Sending = true;
    }
    else {
      this.Sending = false;
    }
  }

  public showAdditionalDataMissing(): void {
    this.showForm = false;
    this.showAdditionalData = true;
    window.scrollTo(0, document.body.scrollHeight);
  }

  public getComments(): void {

    this.csrService.getCommentsAssignedToMy()
      .subscribe((res: any) => {

        this.commentsAssignedToMy = res as Comment[];

        this.commentsAssignedToMyService.updateComments(this.commentsAssignedToMy);
      },
        (error: any) => {

          this.messageService.add({ key: 'csreditkey', severity: 'error', summary: 'Error', detail: error });

        });
  }

  public handleChange(event: any): void {

    if (event.value.value === 'Waiting for additional information') {

      this.clearValidators();

      if (this.issue.inProgressDate === null) {
        this.issue.inProgressDate = new Date();     
      }

      this.showForm = true;

    } else if (event.value.value === 'Completed') {

      if (this.issue.inProgressDate === null) {
        this.inprogressdateValidator = true;
        this.issueForm.form.get('inprogressdate')!.setValidators([Validators.required]);
        this.issueForm.form.get('inprogressdate')!.updateValueAndValidity();
      }

      if (this.issue.completedDate === null) {
        this.completeddateValidator = true;
        this.issueForm.form.get('completeddate')!.setValidators([Validators.required]);
        this.issueForm.form.get('completeddate')!.updateValueAndValidity();
      }

      if (this.issue.resolution === null) {
        this.issueForm.form.get('resolution')!.setValidators([Validators.required]);
        this.issueForm.form.get('resolution')!.updateValueAndValidity();
      }

    } else if (event.value.value === 'In Progress') {

      if (this.issue.inProgressDate === null) {
        this.inprogressdateValidator = true;
        this.issueForm.form.get('inprogressdate')!.setValidators([Validators.required]);
        this.issueForm.form.get('inprogressdate')!.updateValueAndValidity();
      }

      if (this.issue.completedDate === null) {
        this.completeddateValidator = false;
        this.issueForm.form.get('completeddate')!.clearValidators();
        this.issueForm.form.get('completeddate')!.updateValueAndValidity();
      }

      if (this.issue.resolution === null) {
        this.issueForm.form.get('resolution')!.clearValidators();
        this.issueForm.form.get('resolution')!.updateValueAndValidity();
      }

    } else {

      this.clearValidators();
    }
  }

  public clearValidators(): void {

    if (this.issue.inProgressDate === null) {
      this.inprogressdateValidator = false;
      this.issueForm.form.get('inprogressdate')!.clearValidators();
      this.issueForm.form.get('inprogressdate')!.updateValueAndValidity();
    }

    if (this.issue.completedDate === null) {
      this.completeddateValidator = false;
      this.issueForm.form.get('completeddate')!.clearValidators();
      this.issueForm.form.get('completeddate')!.updateValueAndValidity();
    }

    if (this.issue.resolution === null) {
      this.issueForm.form.get('resolution')!.clearValidators();
      this.issueForm.form.get('resolution')!.updateValueAndValidity();
    }
  }

  public onSelectInProgressDate(): void {

    //const dateTime = new Date();
    //this.issue.inProgressDate = dateTime;
    this.inprogressdateValidator = false;
  }

  public onSelectCompletedDate(): void {

    //const dateTime = new Date();
    //this.issue.completedDate = dateTime;
    this.completeddateValidator = false;
  }

  public handleChangeDepartment(event: any): void {

    this.getProjects(event.value.id);  

    if (this.department.name === 'LWH Software/Hardware') {
      this.ProgramTitle = 'Program';
    }
    else if (this.department.name === 'Laboratory Instrumentation') {
      this.ProgramTitle = 'Instrument';
    }
    else if (this.department.name === 'Building Facility & Maintenance') {
      this.ProgramTitle = 'Location';
    }
    else if (this.department.name === 'Phone and Outbound Networks') {
      this.ProgramTitle = 'Location';
    }
    else if (this.department.name === 'Reagents Issues') {
      this.ProgramTitle = 'Department';
    }
    else {
      this.ProgramTitle = 'other department';
    }

    this.getIssueTypesPerDepartment();
  }

  public assignedToMy(): void {

    const userLogged = this.getUserLogged();

    const assignedtomy = this.users.filter((x) => x.id === userLogged.id);

    if (assignedtomy !== undefined) {
      this.assignedto = assignedtomy[0];
    }
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

  public getUserLogged(): User {

    return this.accountService.getLoggedInUser();
  }

}
