import { Component, OnInit, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';

//Service
import { AccountService } from '../account.service';
import { UserLoggedInService } from '../user-logged-in.service';

//Core
import { OnlyToken } from './only-token';
import { ReturnOnlyToken } from './return-only-token';

//primeng
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-auto-logout',
  templateUrl: './auto-logout.component.html'
})
export class AutoLogoutComponent extends OnDestroyMixin implements OnInit {

  public timedOut = false;
  public idleInterval = 5;
  public timeOutInterval = 30;
  public displayAutoLogoffDialog: boolean;
  public expireInSeconds: number;
  public onlyToken: OnlyToken;
  public aboutToExpireMessage: string;
  public aboutToExpireSecondsMessage: string;

  public constructor(private readonly idle: Idle,
    private readonly keepalive: Keepalive,
    private readonly appRef: ApplicationRef,
    private readonly messageService: MessageService,
    private readonly router: Router,
    private readonly accountService: AccountService,
    private readonly userLoggedInService: UserLoggedInService) {
    super();
  }

  public ngOnInit(): void {

    this.onlyToken = {} as OnlyToken;

    this.aboutToExpireMessage = 'Your session is about to expire in';
    this.aboutToExpireSecondsMessage = 'seconds.!';

    this.accountService.getExpiredSessionTime()
      .pipe(untilComponentDestroyed(this))
      .subscribe((timeoutInSeconds) => {

        this.idleInterval = timeoutInSeconds;

        this.configureAutoLogout();
      },
        (error: any) => {

          this.messageService.add({ key: 'autologoutkey', severity: 'error', summary: 'Error', detail: error });

        });

    this.updateUserLoggedIn();
  }

  private configureAutoLogout(): void {

    // sets an idle timeout of 5 seconds, for testing purposes.
    this.idle.setIdle(this.idleInterval);

    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(this.timeOutInterval);

    const keepAliveInterval = 30;

    this.keepalive.interval(keepAliveInterval);

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.keepalive.onPing
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {

        this.accountService.isUserStillLogged()
          .subscribe(() => {
          });
      });

    // 'No longer idle.'
    this.idle.onIdleEnd
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {

        this.displayAutoLogoffDialog = false;
        console.log('onIdleEnd');
        this.appRef.tick();
        this.refreshToken();
      });

    // Time out
    this.idle.onTimeout
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {

        this.displayAutoLogoffDialog = false;
        this.timedOut = true;
        console.log('onTimeout');
        this.logout();
      });

    // You've gone idle!
    this.idle.onIdleStart
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        console.log('onIdleStart');
        this.displayAutoLogoffDialog = true;
      });

    // You will time out in x seconds!
    this.idle.onTimeoutWarning
      .pipe(untilComponentDestroyed(this))
      .subscribe((countdown: number) => {

        this.expireInSeconds = countdown;
      });


    if (this.isUserLoggedIn() === true) {
      this.reset();
    } else {
      this.idle.stop();
    }

    //this.reset();
  }

  private reset(): void {

    this.idle.watch();
    this.timedOut = false;
  }

  public logout(): void {

    this.accountService.logout()
      .subscribe(() => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');

        this.userLoggedInService.setUserLoggedIn(false);

        const timeoutInMilliseconds = 1000;

        setTimeout(() => {

          this.router.navigate(['/account/login']);
          //window.location.reload();

        }, timeoutInMilliseconds);
      },
        (error: any) => {

          this.messageService.add({ key: 'autologoutkey', severity: 'error', summary: 'Error', detail: error });

        });
  }

  public refreshToken(): void {

    const userLogged = this.accountService.getLoggedInUser();

    if (userLogged !== undefined) {

      this.onlyToken = { userName: userLogged.userName, email: userLogged.email } as OnlyToken;

      this.accountService.refreshToken(this.onlyToken)
        .subscribe((res: ReturnOnlyToken) => {
          localStorage.removeItem('token');
          localStorage.setItem('token', res.token);

          this.userLoggedInService.setUserLoggedIn(true);
        },
          (error: any) => {

            this.messageService.add({ key: 'autologoutkey', severity: 'error', summary: 'Error', detail: error });

          });
    }
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

  public updateUserLoggedIn(): void {

    this.userLoggedInService.getUserLoggedIn().subscribe(isLogin => {

      if (isLogin === true) {
        this.reset();
      } else {
        this.idle.stop();
      }

    });

  }

}
