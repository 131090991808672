//Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from '../core/base.service';

//Core
import { Csr } from './csr';
import { MyCsr } from './my-csr/my-csr';
import { Icsr } from './dashboard/icsr';
import { AssignedToList } from '../csr/quick-search/assigned-to-list'
import { NewCsrsCounter } from './new-csrs-counter';

@Injectable()
export class CsrService extends BaseService {

  public _baseUrlToAPI = 'api/csr';

  constructor(private readonly http: HttpClient) {
    super();
  }

  public getCommentsAssignedToMy(): Observable<Comment[]> {

    return this.http.get<Comment[]>(`${this._baseUrlToAPI}/comments-assigned-to-my`);
  }

  public getMyCsrs(userid: string): Observable<MyCsr[]> {

    return this.http.get<MyCsr[]>(`${this._baseUrlToAPI}/mycsrs/${userid}`);
  }

  public getCsr(id: number): Observable<any> {

    return this.http.get<any>(`${this._baseUrlToAPI}/${id}`);
  }

  public getCsrsPerDepartment(departmentid: number): Observable<Csr[]> {

    return this.http.get<Csr[]>(`${this._baseUrlToAPI}/perdepartment/${departmentid}`)
  }

  public searchCsrBySubject(searchText: string): Observable<Icsr[]> {

    return this.http.get<Icsr[]>(`${this._baseUrlToAPI}/search-csr-by-subject/${searchText}`);
  }

  public getCsrByStatus(status: string, departmentid: number): Observable<Csr[]> {

    return this.http.get<Csr[]>(`${this._baseUrlToAPI}/status/${status}/${departmentid}/All`)
  }

  public getCsrByStatusNumberOfRecords(status: string, departmentid: number, numberOfRecords: string): Observable<Csr[]> {

    return this.http.get<Csr[]>(`${this._baseUrlToAPI}/status/${status}/${departmentid}/${numberOfRecords}`);
  }

  public getAssignedToList(status: string, departmentid: number): Observable<AssignedToList[]> {

    return this.http.get<AssignedToList[]>(`${this._baseUrlToAPI}/assignedtolist/status/${status}/${departmentid}`);
  }

  public editCsr(csr: Csr): Observable<any> {
    
    return this.http.put<any>(this._baseUrlToAPI, csr, { headers: this.PutPostHeaders });
  }

  public addCsr(csr: Csr): Observable<any> {

    return this.http.post<any>(this._baseUrlToAPI, csr, { headers: this.PutPostHeaders });
  }

  public deleteCsr(id: string): Observable<any> {

    return this.http.delete<any>(`api/csr/${id}`);
  }

  public checkAddNewAttachment(id: number, userId: string): Observable<any> {

    return this.http.get<any>(`${this._baseUrlToAPI}/check-add-new-attachment/${id}/${userId}`);
  }

  public getNewCsrs(userid: string): Observable<NewCsrsCounter[]> {

    return this.http.get<NewCsrsCounter[]>(`${this._baseUrlToAPI}/get-new-csrs/${userid}`);
  }

  //private handleError(error): Observable<any> {

  //  let errorMessage = '';

  //  if (error.error instanceof ErrorEvent) {

  //    // client-side error
  //    errorMessage = `Error: ${error.error.message}`;

  //  } else {

  //    // server-side error
  //    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  //  }

  //  //window.alert(errorMessage);
  //  return throwError(errorMessage);
  //}
}
