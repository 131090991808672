//Angular
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

//Services
import { CsrService } from '../../csr/csr.service';
import { CommentsAssignedToMyService } from '../../csr/comments-assigned-to-my.service';
import { AccountService } from '../account.service';
import { UserLoggedInService } from '../user-logged-in.service';
import { AppBreadcrumbService } from '../../menu/app.breadcrumb.service';

//Core
import { AuthUser } from '../model/authUser';
import { Comment } from '../../csr/comment/comment';
import { UserLogin } from './user-login';

//Primeng
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit {

  public userLogin: UserLogin;
  public loading = false;
  public commentsAssignedToMy: Comment[] = [];
  //public expiredLoginTokenMessage = 'Login time expired!';
  //public expiredLoginTokenBoolean = false;

  constructor(private readonly accountService: AccountService,
    private readonly csrService: CsrService,
    private readonly messageService: MessageService,
    private commentsAssignedToMyService: CommentsAssignedToMyService,
    private readonly router: Router,
    private readonly userLoggedInService: UserLoggedInService,
    private readonly breadcrumbService: AppBreadcrumbService) {

    this.breadcrumbService.setItems([
      { label: 'account' },
      { label: 'login', routerLink: ['/account/login'] }
    ]);

  }

  public ngOnInit(): void {

    this.userLogin = { username: '', password: '' } as UserLogin;
    //this.updateMenuLogin();
  }

  public login(): void {
    this.loading = true;
    this.accountService.login(this.userLogin)
      .subscribe((user: AuthUser) => {

        if (user) {
          //localStorage.setItem('token', user.token);
          //localStorage.setItem('user', JSON.stringify(user.returnUser));
          this.accountService.storeUserData(user.token, user.returnUser);
        }

        if (user.returnUser.roles.length > 0) {

          const userRoles = user.returnUser.roles.find(row => row === 'Admin' || row === 'SuperAdmin');

          if (userRoles !== undefined) {
            this.router.navigate(['/csr/department', 1]);
          }
          else {
            this.router.navigate(['/csr/my-csr']);
          }          
        }

        this.getComments();

        this.loading = false;

        this.userLoggedInService.setUserLoggedIn(true);
        
      },
        (error: any) => {          

          this.messageService.add({ key:'loginkey', severity: 'warn', summary: 'Warn', detail: error });

          this.loading = false;

        });
  }  

  public getComments(): void {

    this.csrService.getCommentsAssignedToMy()
      .subscribe((res: any) => {

        this.commentsAssignedToMy = res as Comment[];

        this.commentsAssignedToMyService.updateComments(this.commentsAssignedToMy);
      },
        (error: any) => {

          this.messageService.add({ key: 'loginkey', severity: 'error', summary: 'Error', detail: error });

        });
  }

  ////public updateMenuLogin(): void {

  ////  this.userLoggedInService.getUserLoggedIn().subscribe(x => {

  ////    this.expiredLoginTokenBoolean = x;

  ////  });

  ////}

}
