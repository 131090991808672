//Angular
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

//Services
import { GuideService } from '../guide.service';
import { AccountService } from '../../account/account.service';
import { AppBreadcrumbService } from '../../menu/app.breadcrumb.service';

//Core
import { GuideGroupByProjects } from './guide-group-by-projects';

//Primeng
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-guide-group-by-projects',
  templateUrl: './guide-group-by-projects.component.html'
})
export class GuideGroupByProjectsComponent implements OnInit {

  public title = 'Implementation Guide';
  public guideGroupByProjects: GuideGroupByProjects[];
  public progressBar = false;
  public indeterminateProgressBar = true;
  /*public blockedPanel = true;*/

  constructor(private readonly accountService: AccountService,
    private readonly guideService: GuideService,
    private readonly messageService: MessageService,
    private readonly router: Router,
    private readonly breadcrumbService: AppBreadcrumbService  ) {
  }

  public ngOnInit(): void {

    if (this.isUserLoggedIn()) {
      this.getGuideGroupByProjects();

      this.breadcrumbService.setItems([
        { label: 'guide' },
        { label: 'guide-group-by-projects', routerLink: ['/guide/guide-group-by-projects'] }
      ]);
    } else {
      this.indeterminateProgressBar = false;
      //this.blockedPanel = false;
      this.router.navigate(['/account/login']);
    }
  }

  public getGuideGroupByProjects(): void {

    this.guideService.getGuideGroupByProjects()
      .subscribe((res: any) => {

        this.guideGroupByProjects = res as GuideGroupByProjects[];

        this.indeterminateProgressBar = false;
        //this.blockedPanel = false;
      },
        (error: any) => {

          this.indeterminateProgressBar = false;
          //this.blockedPanel = false;

          this.messageService.add({ key: 'guidegroupbyprojectskey', severity: 'error', summary: 'Error', detail: error });
          
        });
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

}
