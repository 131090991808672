//Angular
import { NgModule } from '@angular/core';

//Shared
import { SharedModule } from '../shared/shared.module';

//Services
import { AuthGuard } from '../account/auth.guard';
import { AccountService } from './account.service';
import { UserLoggedInService } from './user-logged-in.service';

//Component
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AccountComponent } from './account.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { UserNotAuthorizedComponent } from './user-not-authorized/user-not-authorized.component';
import { UserComponent } from './users/user.component';
import { UsersComponent } from './users/users.component';
import { AutoLogoutComponent } from './auto-logout/auto-logout.component';

@NgModule({
  imports: [

    SharedModule
  ],
  exports: [
    AutoLogoutComponent
  ],
  declarations: [
    AccountComponent,
    LoginComponent,
    ChangePasswordComponent,
    RegisterComponent,
    UserNotAuthorizedComponent,
    UsersComponent,
    UserComponent,
    AutoLogoutComponent
  ],

  providers: [

    AuthGuard,
    AccountService,
    UserLoggedInService
  ]
})
export class AccountModule { }
