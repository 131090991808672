import { Component } from '@angular/core';

@Component({
  selector: 'app-compensatory-time',
  templateUrl: './compensatory-time.component.html'
})

export class CompensatoryTimeComponent {
  constructor() { }
}
