import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { enableProdMode } from '@angular/core';
enableProdMode();

import { User } from './model/user';
import { AccountService } from './account.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private readonly accountService: AccountService,
    private readonly router: Router) { }

  /* tslint:disable:no-unused */
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    /* tslint:enable:no-unused */

    if (this.isUserLoggedIn()) {

      const userLogged = this.getUser();

      const url = state.url;

      return this.checkRoles(url, userLogged.roles);
    }

    this.router.navigate(['/account/login']);

    return false;
  }

  public checkRoles(url: string, roles: string[]): Observable<boolean> | Promise<boolean> | boolean {
    const rolePass: string[] = [];

    switch (url) {
      case '/account/register':
        rolePass.push('SuperAdmin');
        break;
      case '/account/change-password':
        rolePass.push('User', 'Admin', 'SuperAdmin');
        break;
      case '/account/users/0':
        rolePass.push('SuperAdmin');
        break;
      case '/account/user-not-authorized':
        rolePass.push('User', 'Admin', 'SuperAdmin');
        break;
      case '/csr/dashboard':
        rolePass.push('Admin', 'SuperAdmin');
        break;
      case '/client/clients/0':
        rolePass.push('Admin', 'SuperAdmin');
        break;
      case '/project/projects/0':
        rolePass.push('Admin', 'SuperAdmin');
        break;
      case '/guide/guide-group-by-projects':
        rolePass.push('User', 'Admin', 'SuperAdmin');
        break;
      case '/guide/guide-add/0':
        rolePass.push('Admin', 'SuperAdmin');
        break;
      case 'guide/apps-tutorial':
        rolePass.push('User', 'Admin', 'SuperAdmin');
        break;
      case '/mis-resultados/pie-chart':
        rolePass.push('Admin', 'SuperAdmin');
        break;
      case '/mis-resultados/bar-chart':
        rolePass.push('Admin', 'SuperAdmin');
        break;
      case '/csr/my-csr':
        rolePass.push('User', 'Admin', 'SuperAdmin');
        break;
      case '/csr/issue-type/0':
        rolePass.push('Admin', 'SuperAdmin');
        break;
      case '/compensatory-time/user-compensatory-time':
        rolePass.push('SuperAdmin');
        break;
      default:
        if (url.includes('/account/user/')) {
          rolePass.push('SuperAdmin');
        }
        else if (url.includes('/client/client-details/')) {
          rolePass.push('Admin', 'SuperAdmin');
        }
        else if (url.includes('/project/project-details/')) {
          rolePass.push('Admin', 'SuperAdmin');
        }
        else if (url.includes('/csr/csr-details/')) {
          rolePass.push('User', 'Admin', 'SuperAdmin');
        }
        else if (url.includes('/csr/csr-edit/')) {
          rolePass.push('Admin', 'SuperAdmin');
        }
        else if (url.includes('/csr/csr-add/')) {
          rolePass.push('User', 'Admin', 'SuperAdmin');
        }
        else if (url.includes('/csr/csr-details/')) {
          rolePass.push('User', 'Admin', 'SuperAdmin');
        }
        else if (url.includes('/csr/quick-search/')) {
          rolePass.push('User', 'Admin', 'SuperAdmin');
        }
        else if (url.includes('/guide/guide-details/')) {
          rolePass.push('User', 'Admin', 'SuperAdmin');
        }
        else if (url.includes('/csr/department/1')) {
          rolePass.push('Admin', 'SuperAdmin');
        }
        else {
          rolePass.push('User', 'Admin', 'SuperAdmin');
          break;
        }
    }

    for (const role of roles) {

      const lst = rolePass.filter((x) => x === role) as string[];

      if (lst.length > 0) {

        return true;
      }

    }

    this.router.navigate(['/account/user-not-authorized']);

    return false;
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

  public getUser(): User {

    return this.accountService.getLoggedInUser();
  }
}
