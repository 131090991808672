//Angualr
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../core/base.service';
import { Router } from "@angular/router";

//Core
import { AuthUser } from './model/authUser';
import { ChangePassword } from './model/change-password';
import { Registration } from './model/registration';
import { User } from './model/user';
import { UserLogin } from '../account/login/user-login';
import { OnlyToken } from '../account/auto-logout/only-token';
import { ReturnOnlyToken } from '../account/auto-logout/return-only-token';
import { RequestAuthorizationDate } from '../csr/csr-details/requestAuthorizationDate';

@Injectable()
export class AccountService extends BaseService {

  private readonly apiUrl = 'api/account';
  public userKey = 'user';

  constructor(private readonly http: HttpClient,
    private readonly router: Router) {
    super();
  }

  public register(newUser: Registration): Observable<any> {

    return this.http.post<any>(`${this.apiUrl}/register/`, newUser, { headers: this.PutPostHeaders });
  }

  //public login(model: User): Observable<any> {

  //  const body = JSON.stringify(model);

  //  return this.http.post<any>(`${this.apiUrl}/authenticate/`, body, { headers: this.PutPostHeaders })
  //    .pipe(
  //      map((res: any) => res.json() as AuthUser),
  //      catchError(this.handleError));
  //}

  public login(userLogin: UserLogin): Observable<AuthUser> {

    const body = JSON.stringify(userLogin);

    return this.http.post<AuthUser>(`${this.apiUrl}/login`, body, { headers: this.PutPostHeaders });
  }

  public refreshToken(onlyToken: OnlyToken): Observable<ReturnOnlyToken> {

    const body = JSON.stringify(onlyToken);

    return this.http.post<ReturnOnlyToken>(`${this.apiUrl}/refresh-token`, body, { headers: this.PutPostHeaders });
  }

  public logout(): Observable<any> {

    return this.http.post(`${this.apiUrl}/logout`, undefined, { headers: this.PutPostHeaders, responseType: 'text' });
  }

  public changePassword(user: ChangePassword): Observable<any> {
    
    return this.http.put<any>(`${this.apiUrl}/changepassword/`, user, { headers: this.PutPostHeaders });
  }

  public resetPassword(user: ChangePassword): Observable<any> {
    
    return this.http.put<any>(`${this.apiUrl}/resetpassword/`, user, { headers: this.PutPostHeaders });
  }  

  public isUserAuthenticated(): boolean {

    if (localStorage.getItem(this.userKey)) {
      // logged in so return true
      return true;
    }

    return false;
  }

  public getLoggedInUser(): User {

    let user: User = { id: '0' } as User;

    if (this.isUserAuthenticated()) {

      const userString: any = localStorage.getItem(this.userKey);

      user = JSON.parse(userString) as User;

      return user;
    }

    return user;
  }

  public getAllUsers(): Observable<any> {

    return this.http.get<any>(`${this.apiUrl}/users/`);
  }  

  public getUsers(requestType: number): Observable<any> {

    return this.http.get<any>(`${this.apiUrl}/usersandroles/${requestType}`);
  }

  public getShortUsersList(): Observable<any> {

    return this.http.get<any>(`${this.apiUrl}/get-short-user-list`);
  }

  public getUser(id: string): Observable<User> {
    return this.http.get<User>(`${this.apiUrl}/user/${id}`);
  }

  public sendTextMessage(requestAuthorizationDate: RequestAuthorizationDate): Observable<any> {

    return this.http.post<any>(`${this.apiUrl}/send-text-message/`, requestAuthorizationDate, { headers: this.PutPostHeaders });
  }

  public getRoles(): Observable<any> {

    return this.http.get<any>(`${this.apiUrl}/roles/`);
  }

  public getUserRoles(id: string): Observable<any> {

    return this.http.get<any>(`${this.apiUrl}/userroles/${id}`);
  }

  public getExpiredSessionTime(): Observable<any> {

    return this.http.get<any>(`${this.apiUrl}/expired-session-time/`);
  }

  public addNewRole(userid: string, roleid: string): Observable<any> {

    return this.http.post<any>(`${this.apiUrl}/addnewuserrole/${userid}/${roleid}`, '');
  }

  public deleteUserRole(userid: string, roleid: string): Observable<any> {

    return this.http.delete<any>(`${this.apiUrl}/deleteuserrole/${userid}/${roleid}`);
  }

  public uploadAvatar(formData: FormData): Observable<any> {

    return this.http.post<any>(`${this.apiUrl}/uploadAvatar`, formData);
  }

  public isUserStillLogged(): Observable<void> {

    return this.http.get(`${this.apiUrl}/heartbeat`, { responseType: 'text' })
      .pipe(
        map(() => { }));
  }

  public storeUserData(token, user): void {

    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    //this.expirationCounter(this.timeout);
  }

  //public expirationCounter(timeout): void {
  //  this.tokenSubscription.unsubscribe();
  //  this.tokenSubscription = of(null).pipe(delay(timeout)).subscribe((expired) => {
  //    console.log('EXPIRED!!');
  //    this.logout();
  //    localStorage.removeItem('token');
  //    localStorage.removeItem('user');      
  //    this.updateMenuLoginService.updateMenuLogin(false);
  //    this.updateMenuLoginService.setExpiredLoginToken(true);
  //    this.router.navigate(['/account/login']);
  //  });
  //}
}
