//Angular
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

//Services
import { CompensatoryTimeService } from '../compensatory-time.service';
import { AccountService } from '../../account/account.service';

//Core
import { CompensatoryTime } from '../compensatory-time';
import { User } from '../../account/model/user';

//Primeng
import { MessageService, Message } from 'primeng/api';

@Component({
  selector: 'app-user-compensatory-time',
  templateUrl: './user-compensatory-time.component.html'
})
export class UserCompensatoryTimeComponent implements OnInit {

  public title = 'Compensatory Time';
  public compensatoryTimes: CompensatoryTime[] = [];
  public compensatoryTime: CompensatoryTime;
  public _user: User;
  public users: User[];
  public cols: any[];
  public loading = false;
  public displayDialog = false;
  public selectedUser: User;
  public progressBarSendEmail = false;
  public calTotalTime = 0;
  public msgs: Message[];

  constructor(private readonly compensatoryTimeService: CompensatoryTimeService,
    private readonly accountService: AccountService,
    private readonly messageService: MessageService,
    private readonly router: Router) {
  }

  public ngOnInit(): void {

    if (this.isUserLoggedIn()) {
      this._user = this.getlocalUser();
      const dateNow = new Date();
      this.compensatoryTime = { id: 0, userId: this._user.id, name: this._user.name, compensatoryDate: dateNow } as CompensatoryTime;
      this.getUsers();
    } else {
      this.router.navigate(['/account/login']);
    }
  }

  public showDialogAddNewRecord(): void {

    const dateNow = new Date();
    this.compensatoryTime = this.compensatoryTime = { id: 0, userId: this._user.id, name: this._user.name, compensatoryDate: dateNow } as CompensatoryTime;

    this.displayDialog = true;
  }

  public onSubmit(form: NgForm): void {

    this.loading = true;

    this.compensatoryTimeService.addNewRecord(this.compensatoryTime)
      .subscribe((data: any) => {

        this.loading = false;
        this.displayDialog = false;
        this.messageService.add({ key: 'uctkey', severity: 'success', summary: 'Compensatory Time', detail: 'The record added successfully.' });
        this.getUserCompensatoryTime(false);
      },
        (error: any) => {

          this.loading = false;
          this.messageService.add({ key: 'uctkey', severity: 'error', summary: 'Error', detail: error });
        });

    form.resetForm();
  }

  public calculateTime(startTime: string, endTime: string): void {

    if (this.compensatoryTime.startTime === undefined || this.compensatoryTime.startTime === null) {

      this.messageService.add({ key: 'uctkey', severity: 'warn', summary: 'Warn', detail: 'You must enter both beginning and ending time.' });

      return;
    }

    if (this.compensatoryTime.endTime === undefined || this.compensatoryTime.endTime === null) {
      
      this.messageService.add({ key: 'uctkey', severity: 'warn', summary: 'Warn', detail: 'You must enter both beginning and ending time.' });

      return;
    }

    if (this.compensatoryTime.startTime > this.compensatoryTime.endTime) {
  
      this.messageService.add({ key: 'uctkey', severity: 'warn', summary: 'Warn', detail: 'Ending time must be greater than beginning time.' });

      return;
    }

    const diffInMs: number = Date.parse(endTime) - Date.parse(startTime);

    const diffInHours: number = diffInMs / 1000 / 60 / 60;

    const resNun = diffInHours.toFixed(2);

    console.log(resNun);

    this.compensatoryTime.totalTime = parseFloat(resNun);
  }

  public getUsers(): void {

    this.accountService.getUsers(1)
      .subscribe((res: any) => {
        this.users = res as User[];

        this.selectedUser = { id: 'All', name: 'All' } as User;

        this.users.push(this.selectedUser);

        this.getUserCompensatoryTime(false);
      },
        (error: any) => {

          this.messageService.add({ key: 'uctkey', severity: 'error', summary: 'Error', detail: error });
          
        });
  }

  public getUserCompensatoryTime(sendbyemail: boolean): void {

    if (sendbyemail === true) {

      this.progressBarSendEmail = true;
    }

    this.compensatoryTimeService.getCompensatoryTimes(this.selectedUser.id, sendbyemail)
      .subscribe((res: any) => {
        this.compensatoryTimes = res as CompensatoryTime[];

        if (this.compensatoryTimes.length > 0) {
          this.calTotalTime = this.compensatoryTimes[0].calTotalTime;
        }

        this.loadColumns();

        this.loading = false;
        this.progressBarSendEmail = false;

        if (sendbyemail === true) {

          this.messageService.add({ key: 'uctkey', severity: 'success', summary: 'Error', detail: 'Email sent successfully' });
        }
      },
        (error: any) => {

          this.messageService.add({ key: 'uctkey', severity: 'error', summary: 'Compensatory Times', detail: error });

        });
  }

  public loadColumns(): void {

    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'compensatoryDate', header: 'Date' },
      { field: 'startTime', header: 'Start Time' },
      { field: 'endTime', header: 'End Time' },
      { field: 'totalTime', header: 'Time' },
      { field: 'cancelTime', header: 'Negative' },
      { field: 'description', header: 'Description' }
    ];

    this.loading = true;
  }

  public getlocalUser(): User {

    return this.accountService.getLoggedInUser();
  }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

}
