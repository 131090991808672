import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// Services
import { GuideService } from '../guide.service';

// Core
import { Guide } from '../guide';

//Primeng
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html'
})

export class VideoComponent implements OnInit {

  public guide: Guide = {} as Guide;
  public id = 0;
  public videoUrl = 'video';
  public waitingScreen = false;

  constructor(private readonly guideService: GuideService,
    private readonly messageService: MessageService,
    private readonly route: ActivatedRoute) {
  }
  public ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.downloadVideoFile();
  }

  //public getVideo(): void {

  //  this.waitingScreen = true;

  //  this.guideService.getGuide(this.id)
  //    .subscribe((res: Guide) => this.guide = res as Guide,
  //      (error: any) => {
  //        this.waitingScreen = false;
  //        this.messageService.add({ key: 'videokey', severity: 'error', summary: 'Error', detail: error });
  //      },
  //      () => {
  //        this.downloadVideoFile(this.guide.id);
  //        //this.videoUrl = `/videos/${this.guide.fileName}`;
  //        //console.log(this.videoUrl);
  //      });
  //}

  public downloadVideoFile(): void {

    this.waitingScreen = true;

    this.guideService.downloadPdfFile(this.id)
      .subscribe((data: any) => {

        //const objectUrl = URL.createObjectURL(data);
        //this.videoUrl = objectUrl;

        this.waitingScreen = false;
        var video = document.querySelector('video');
        var videoUrl = window.URL.createObjectURL(data);
        video.src = videoUrl;
        
      },
        (error: any) => {
          this.waitingScreen = false;
          this.messageService.add({ key: 'videokey', severity: 'error', summary: 'Error', detail: error });
        });

  }

}
