//Angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

//Shared
import { SharedModule } from '../shared/shared.module';

//Services
import { ProjectService } from './project.service';

//Component
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { ProjectComponent } from './project.component';
import { ProjectsComponent } from './projects/projects.component';

@NgModule({
  imports: [

    CommonModule,
    SharedModule
  ],
  exports: [
  ],
  declarations: [
    ProjectComponent,
    ProjectsComponent,
    ProjectDetailsComponent
  ],

  providers: [

    ProjectService
  ]
})
export class ProjectModule { }
