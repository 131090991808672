//Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from '../core/base.service';

//Core
import { Client } from './client';

@Injectable()
export class ClientsService extends BaseService {

  public _baseUrlToAPI = 'api/client';

  constructor(private http: HttpClient) {
    super();
  }

  public getClient(id: number): Observable<Client> {

    return this.http.get<Client>(`${this._baseUrlToAPI}/${id}`);
  }

  public getClients(): Observable<Client[]> {

    return this.http.get<Client[]>(this._baseUrlToAPI)
  }

  public addClient(client: Client): Observable<any> {
    
    return this.http.post<any>(this._baseUrlToAPI, client, { headers: this.PutPostHeaders });
  }

  public editClient(client: Client): Observable<any> {
    
    return this.http.put<any>(this._baseUrlToAPI, client, { headers: this.PutPostHeaders });
  }

  public deleteClient(id: string): Observable<any> {

    return this.http.delete<any>(`${this._baseUrlToAPI}/${id}`);
  }

}
