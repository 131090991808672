//Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from '../core/base.service';

//Core
import { CompensatoryTime } from './compensatory-time';

@Injectable()
export class CompensatoryTimeService extends BaseService {

  public _baseUrlToAPI = 'api/compensatorytime';

  constructor(private http: HttpClient) {
    super();
  }

  public getCompensatoryTimes(userId: string, sendbyemail: boolean): Observable<CompensatoryTime[]> {

    return this.http.get<CompensatoryTime[]>(`${this._baseUrlToAPI}/AllOrUser/${userId}/${sendbyemail}`);
  }

  public addNewRecord(compensatoryTime: CompensatoryTime): Observable<any> {

    return this.http.post<any>(this._baseUrlToAPI, compensatoryTime, { headers: this.PutPostHeaders });
  }

}
