//Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from '../../core/base.service';

//Core
import { Comment } from './/comment';

@Injectable()
export class CommentService extends BaseService {

  public _baseUrlToAPI = 'api/comment';

  constructor(private readonly http: HttpClient) {
    super();
  }

  public getComments(id: number): Observable<Comment[]> {

    return this.http.get<Comment[]>(`${this._baseUrlToAPI}/${id}`);
  }

  public addComment(comment: Comment): Observable<any> {

    return this.http.post<any>(this._baseUrlToAPI, comment, { headers: this.PutPostHeaders });
  }

  public editComment(comment: Comment): Observable<any> {

    return this.http.put<any>(this._baseUrlToAPI, comment, { headers: this.PutPostHeaders });
  }

  public deleteComment(id: string): Observable<any> {

    return this.http.delete<any>(`${this._baseUrlToAPI}/${id}`);
  }

}
