import {Component} from '@angular/core';
import {AppMainComponent} from './app.main.component';

@Component({
    selector: 'app-rightpanel',
    template: `
		<div class="layout-rightpanel" [ngClass]="{'layout-rightpanel-active': app.rightPanelActive}"
			 (click)="app.onRightPanelClick()">
			<div class="layout-rightpanel-wrapper">
				<div class="layout-rightpanel-header">
					<div class="weather-day">Wednesday</div>
					<div class="weather-date">Jan 26</div>
				</div>

				<div class="layout-rightpanel-content">
					<h1>Weather</h1>
					<h2>San Francisco, USA</h2>

					<div class="weather-today">
						<span class="weather-today-value">21&#8451;</span>
					</div>

					<ul class="weekly-weather">
						<li>
							Thursday
						
							<span class="weekly-weather-value">24</span>
						</li>
						<li>
							Friday
						
							<span class="weekly-weather-value">19</span>
						</li>
						<li>
							Saturday
						
							<span class="weekly-weather-value">15</span>
						</li>
						<li>
							Sunday
						
							<span class="weekly-weather-value">24</span>
						</li>
						<li>
							Monday
						
							<span class="weekly-weather-value">21</span>
						</li>
						<li>
							Tuesday
						
							<span class="weekly-weather-value">20</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
    `
})
export class AppRightpanelComponent {
    constructor(public app: AppMainComponent) {
    }
}
