//Angular
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

//Services
import { ClientsService } from '../client.service';

//Core
import { Client } from '../client';

//Primeng
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html'
})
export class ClientsComponent implements OnInit {

  public title = 'Clients';
  public clients: Client[];
  public clientId: string;
  public cols: any[];
  public loading: boolean;
  public deleteModal = false;

  constructor(private readonly clientsService: ClientsService,
    private readonly messageService: MessageService,
    private readonly router: Router) {
  }

  public ngOnInit(): void {

    this.getClients();
  }

  public getClients(): void {

    this.clientsService.getClients()
      .subscribe(res => {

        this.clients = res as Client[];

        this.loadColumns();

        this.loading = false;
      },
        (error: any) => {

          this.messageService.add({ key: 'clientskey', severity: 'error', summary: 'Error', detail: error });
                    
        });
  }

  public selected(id: number, strValue: string): void {

    if (strValue === 'Edit') {
      this.router.navigate([`/client/client-details/${id}`]);
    }
    else {
      this.router.navigate(['/client/client-details/0']);
    }
  }

  public showDeleteConf(id: string): void {
    this.clientId = id;
    this.deleteModal = true;
  }

  public onDelete(): void {

    this.deleteModal = false;

    this.clientsService.deleteClient(this.clientId)
      .subscribe((res: any) => {

        this.messageService.add({ key: 'clientskey', severity: 'success', summary: 'Delete', detail: 'Record deleted successfully' });

        this.getClients();
      },
        (error: any) => {

          this.messageService.add({ key: 'clientskey', severity: 'error', summary: 'Error', detail: error });

        });
  }

  public loadColumns(): void {

    this.cols = [
      { field: 'id', header: 'Id' },
      { field: 'clientname', header: 'Client' },
      { field: 'clientnumber', header: 'Client Number' },
      { field: 'phonenumber', header: 'Phone Number' }
    ];

    this.loading = true;
  }

}
