import { Component, OnDestroy } from '@angular/core';
import { AppBreadcrumbService } from './app.breadcrumb.service';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';

//Services
import { AccountService } from '../account/account.service';

//Primeng
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './app.breadcrumb.component.html'
})
export class AppBreadcrumbComponent implements OnDestroy {

    subscription: Subscription;

    items: MenuItem[];

  constructor(public breadcrumbService: AppBreadcrumbService,
    private accountService: AccountService,
    private readonly messageService: MessageService) {
        this.subscription = breadcrumbService.itemsHandler.subscribe(response => {
            this.items = response;
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

  public isUserLoggedIn(): boolean {

    return this.accountService.isUserAuthenticated();
  }

  public logout(): void {

    this.accountService.logout()
      .subscribe(() => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        // this.router.navigate(['/account/login']);
      },
        error => {

          this.messageService.add({ key: 'breadcrumbkey', severity: 'error', summary: 'Error', detail: error });

        });
  }

}
