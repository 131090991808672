//Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from '../../core/base.service';

//Core
import { Department } from './department';
import { PermissionsReturnValue } from './permissions-return-value';
import { UserDepartmetsPermission } from './user-departments-permission';

@Injectable()
export class DepartmentService extends BaseService  {

  public _baseUrlToAPI = 'api/department';
  public departmentId = 0;

  constructor(private readonly http: HttpClient) {
      super();
  }

  public getDepartments(): Observable<Department[]> {

    return this.http.get<Department[]>(this._baseUrlToAPI);
  }

  public getDepartmentsByUser(userid: string): Observable<Department[]> {

    return this.http.get<Department[]>(`${this._baseUrlToAPI}/get-departments-user/${userid}`);
  }

  public getDepartment(id: number): Observable<Department> {

    return this.http.get<Department>(`${this._baseUrlToAPI}/${id}`);
  }

  public setDepartmentID(id: number): void {

    this.departmentId = id;
    localStorage.removeItem('departmentid');
    localStorage.setItem('departmentid', id.toString());
  }

  public getDepartmentID(): number {

    if (this.departmentId === 0) {
      const departID: any = localStorage.getItem('departmentid');
      this.departmentId = departID;
    }

    return this.departmentId;
  }

  public getAllUserPermission(userid: string): Observable<PermissionsReturnValue[]> {

    return this.http.get<PermissionsReturnValue[]>(`${this._baseUrlToAPI}/get-user-permissions/${userid}`);
  }

  public addNewPermission(model: UserDepartmetsPermission): Observable<PermissionsReturnValue[]> {

    return this.http.post<PermissionsReturnValue[]>(`${this._baseUrlToAPI}/add-new-permission`, model, { headers: this.PutPostHeaders });
  }

  public deleteUserPermission(userid: string, departmentid: number): Observable<any> {

    return this.http.delete<any>(`${this._baseUrlToAPI}/delete-user-permission/${userid}/${departmentid}`);
  }

}
