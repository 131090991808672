//Angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

//Shared
import { SharedModule } from '../shared/shared.module';

//Services
import { CsrService } from './csr.service';
import { CommentsAssignedToMyService } from './comments-assigned-to-my.service';
import { DepartmentService } from '../csr/department/department.service'
import { ImageService } from '../csr/image/image.service';
import { CommentService } from '../csr/comment/comment.service';
import { ProjectService } from '../project/project.service';
import { IssueTypeService } from '../csr/issue-type/issue-type.service';

//Component
import { CsrComponent } from './csr.component';
import { DepartmentComponent } from '../csr/department/department.component';
import { MyCsrComponent } from '../csr/my-csr/my-csr.component';
import { CsrDetailsComponent } from '../csr/csr-details/csr-details.component';
import { ImageComponent } from '../csr/image/image.component';
import { CommentComponent } from '../csr/comment/comment.component';
import { DashboardComponent } from '../csr/dashboard/dashboard.component';
import { QuickSearchComponent } from '../csr/quick-search/quick-search.component';
import { CsrAddComponent } from '../csr/csr-add/csr-add.component';
import { CsrEditComponent } from '../csr/csr-edit/csr-edit.component';
import { IssueTypeComponent } from '../csr/issue-type/issue-type.component';

@NgModule({
  imports: [

    CommonModule,
    SharedModule
  ],
  exports: [
  ],
  declarations: [
    CsrComponent,
    DepartmentComponent,
    MyCsrComponent,
    CsrDetailsComponent,
    ImageComponent,
    CommentComponent,
    DashboardComponent,
    QuickSearchComponent,
    CsrAddComponent,
    CsrEditComponent,
    IssueTypeComponent
  ],

  providers: [
    CsrService,
    CommentsAssignedToMyService,
    DepartmentService,
    ImageService,
    CommentService,
    ProjectService,
    IssueTypeService
  ]
})
export class CsrModule { }
