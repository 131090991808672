//Angular
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

//Services
import { ClientsService } from '../client.service';

//Core
import { Client } from '../client';

//Primeng
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html'
})
export class ClientDetailsComponent implements OnInit {

  public title = 'Client-Details';
  public client: Client;
  public postclientserver: string;
  public IsAddNew: boolean;
  public loading = false;

  constructor(private readonly clientsService: ClientsService,
    private readonly messageService: MessageService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly _location: Location) {
  }

  public ngOnInit(): void {

    const id = this.route.snapshot.params['id'];
    this.client = { id } as Client;

    if (id === '0') {
      this.IsAddNew = true;
      this.title = 'Add New';
    }
    else {
      this.IsAddNew = false;
      this.title = `Edit client: ${id}`;
      this.getClient(id);
    }
  }

  public getClient(id: any): void {

    this.clientsService.getClient(id)

      .subscribe(res => this.client = res as Client,
        (error: any) => {

          this.messageService.add({ key: 'clientdetailskey', severity: 'error', summary: 'Error', detail: error });

        });
  }

  public onSubmit(): void {

    this.loadingSubmit(true);

    if (this.IsAddNew === true) {

      this.clientsService.addClient(this.client)
        .subscribe(data => {

          this.loadingSubmit(false);
          this.messageService.add({ key: 'clientdetailskey', severity: 'success', summary: 'Client', detail: 'The client added successfully.' });
          this.router.navigate(['/client/clients/0']);
        },
          (error: any) => {

            this.loadingSubmit(false);
            this.messageService.add({ key: 'clientdetailskey', severity: 'error', summary: 'Error', detail: error });
          });
    }
    else {

      this.clientsService.editClient(this.client)
        .subscribe(res => {

          this.loadingSubmit(false);
          this.messageService.add({ key: 'clientdetailskey', severity: 'success', summary: 'Client', detail: 'The client updated successfully.' });
          this.router.navigate(['/client/clients/0']);
        },
          (error: any) => {

            this.loadingSubmit(false);

            this.messageService.add({ key: 'clientdetailskey', severity: 'error', summary: 'Error', detail: error });
          });
    }
  }

  public backClicked(): void {

    this._location.back();
  }

  public loadingSubmit(value: boolean): void {

    if (value === true) {
      this.loading = true;
    }
    else {
      this.loading = false;
    }
  }

}
