import { Component } from '@angular/core';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html'
})

export class ProjectComponent {
  constructor() { }
}
